import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "../../../layout";
import {
  mCPF,
  width,
  getCookie,
  formatCurrency,
  formatFloat,
} from "../../../../helpers/general";
import { clienteBloqueado } from "../../../../helpers/core";
import { validateCPF } from "../../../../helpers/validation";
import { sendData, getData, buscarAutorizacao } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import { setProposta, setClienteDados } from "../../../../store/actions/dados";
import { swalError } from "../../../../components/swal";
import "../../../../css/style.css";

let msgIni = { cpf: 0 };
function Refinanciamento({ ...props }) {
  const [cpf, setCpf] = useState();
  const [contrato, setContrato] = useState("");
  const [tabelaList, setTabelaList] = useState([]);
  const [tabelaGlobal, setTabelaGlobal] = useState([]);
  const [convenioList, setConvenioList] = useState([]);
  const [convenioGlobal, setConvenioGlobal] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [limpando, setLimpando] = useState(false);
  const [prazoSteps, setPrazoSteps] = useState([]);
  const [msg, setMsg] = useState(msgIni);
  const [msgErro, setMsgErro] = useState("");
  const [clickedTr, setClickedTr] = useState({});
  const [contratos, setContratos] = useState([]);
  const [tabela_id, setTabela_id] = useState(0);
  const [convenio_id, setConvenio_id] = useState(0);
  const [prazo, setPrazo] = useState(0);
  const [valor, setValor] = useState(0);
  const [consultando, setConsultando] = useState(false);
  const [limpo, setLimpo] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [multSelect, setMultSelect] = useState(true);
  const [showConsultar, setShowConsultar] = useState(false);
  const [tabelasDisponiveis, setTabelasDisponiveis] = useState([]);

  useEffect(() => {
    console.log("Refinanciamento: ", props.detalhes);
    if (props.clienteDados && props.clienteDados.length > 0) {
      console.log("Carregou os contratos de Refin...", props.clienteDados[0]);
      setContratos(props.clienteDados);
      setCpf(mCPF(props.clienteDados[0].numeroCic));
    }

    async function _getConvenios() {
      await sendData({
        uri: uriList("listarConvenios"),
        content: { operacao: "REFINANCIAMENTO", retirarTabelas: true },
        method: "POST",
      })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          if (!Array.isArray(res)) throw new Error();

          setConvenioList(res);
          setConvenioGlobal(res);
          //-- Diogo 12.05.2023 - Retirado para nao filtrar a tabela
          // if (res && res[0]) handleConvenio(res[0].id);
        })
        .catch((e) => setConvenioList([]));
    }

    _getConvenios();
    setTabelasDisponiveis([]);
  }, []);


  async function _getPrazos(tabela_id) {
    setPrazoSteps([]);
    if (tabela_id)
      getData({ uri: uriList("prazos") + "/" + tabela_id })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();

          setPrazoSteps(res);
          setPrazo(res[0].prazo);
        })
        .catch((e) => {
          return false;
        });
  }

  async function _getTabelas(convenio_id) {
    console.log("Refinanciamento.index-getTabelas: ", convenio_id);
    setTabelaList([]);
    handleTabela(0);
    if (convenio_id)
      await sendData({
        uri: uriList("listarTabelas"),
        content: { convenio_id, operacao: "REFINANCIAMENTO" },
        method: "POST",
      })
        .then((res) => {
          console.log("Refinanciamento.index-getTabelas(OK): ", res);
          if (res.status && parseInt(res.status) > 200) throw new Error();
          setTabelaGlobal(res);
          // handleFilterTabelas(res);
          // handleTabela(res[0].id);
        })
        .catch((e) => {
          console.log("Refinanciamento.index-getTabelas(erro): ", e);
          // alert('Erro ao obter Tabelas.\n' + e.message)
        });
  }

  useEffect(() => {
    handleFilterTabelas(tabelaGlobal);
  }, [tabelaGlobal]);


  function limpar() {
    setTabelasDisponiveis([]);
    if (props.reRender) props.reRender();
  }

  async function CarregarDados(cpf, contrato) {
    if (!cpf && !contrato) {
      setTabelasDisponiveis([]);
      return;
    }
    setShowConsultar(false);
    setCarregando(true);
    let content = { cpf, contrato };
    await sendData({
      uri: uriList("consultarCPFBanco"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) {
          throw new Error(res.message);
        }
        console.log("Refinanciamento.index-CarregarDados: ", res.message);
        setContratos(res.message);
        handleContrato(res.message[0]);
        if (!res.message || res.message.length == 0)
          setMsgErro(
            "Não foi possivel encontrar contratos para o CPF informado"
          );

        setCarregando(false);
      })
      .catch((e) => {
        swalError({ title: String(e) });
        console.log(String(e));
        setCarregando(false);
        setMsgErro("Ocorreu um erro ao buscar os contratos do CPF!");
        setShowConsultar(true);
      });
  }

  async function consultarRefin() {
    CarregarDados(cpf, contrato);
  }

  async function handleConvenio(convenio_id) {
    console.log("Refinanciamento.index-handleConvenio: ", convenioList);
    setConvenio_id(convenio_id);
    _getTabelas(convenio_id);
  }

  const handleFilterConvenios = (_contrato) => {
    // console.log("Refinanciamento.handleContrato(1): ", { _contrato, user: props.user })
    let _convenios = convenioGlobal.filter((c) => _contrato?.nomeConvenio?.indexOf(c.nome) >= 0 || c?.nome?.indexOf(_contrato?.nomeConvenio) >= 0 ||
      c.sigla?.indexOf(_contrato?.nomeConvenio) >= 0 || _contrato?.nomeConvenio?.indexOf(c.sigla) >= 0 ||
      c.sigla?.indexOf(_contrato?.numeroConvenio) >= 0 || _contrato?.numeroConvenio?.indexOf(c.sigla) >= 0);
    // console.log("Refinanciamento.handleContrato(convenios): ", _convenios)

    if (!_convenios || _convenios.length == 0)
      _convenios = convenioGlobal

    setConvenioList(_convenios);
    handleConvenio(_convenios[0].id);
  }

  const handleFilterTabelas = (tabelas) => {
    setTabelaList([])
    let _tabelasDisponiveis = [...tabelasDisponiveis]

    // Se o contrato está sendo desmarcado, remove ele das tabelas disponíveis
    if (!clickedTr.selected) {
      _tabelasDisponiveis = _tabelasDisponiveis.filter(t => t.contrato !== clickedTr.numeroContrato)
      setTabelasDisponiveis(_tabelasDisponiveis)
    }

    console.log("Refinanciamento.handleFilterTabelas(5): ", _tabelasDisponiveis)

    if (tabelas && (clickedTr.selected || _tabelasDisponiveis.length > 0)) {
      console.log("Refinanciamento.handleFilterTabelas(2): ", { clickedTr, user: props.user })
      const _tabelas = tabelas.filter((tabela) => {
        //-- Filtrando por tabelas disponíveis
        if (_tabelasDisponiveis.length > 0 && !_tabelasDisponiveis.some(t => t.tabelas?.includes(tabela.id))) {
          return false;
        }

        const _carteira = tabela.tipo_refinanciamento === 'CARTEIRA'
        const _portabilidade = tabela.tipo_refinanciamento === 'PORTABILIDADE'
        const _retencao = tabela.tipo_refinanciamento === 'RETENCAO'

        const _contratoCarteira = (parseInt(clickedTr.qtdParcelasPagas || 0) > 0) || [84, 96, 120].includes(parseInt(clickedTr.qtdParcelasRemanescentes || 0))
        const _contratoRetencao = clickedTr.retencao

        const taxaValida = (!parseFloat(tabela.taxa_refinorg_ini)) || (clickedTr.taxaNominal >= tabela.taxa_refinorg_ini && clickedTr.taxaNominal <= tabela.taxa_refinorg_fin);
        const tipoRefValido = (["ADMINISTRADOR"].indexOf(props.user?.tipo) >= 0) ||
          (_contratoRetencao && (_retencao || _carteira)) || // Se for retenção, só exibe tabelas de retenção
          (!_contratoRetencao && _contratoCarteira && _carteira) || // Se não for retenção e for carteira, exibe tabelas de carteira
          (_portabilidade && (parseInt(clickedTr.qtdParcelasPagas || 0) === 0) && !_contratoCarteira)

        console.log("Refinanciamento.handleFilterTabelas(taxaValida): ", { idTabela: tabela.id, taxaValida, tipoRefValido, _contratoCarteira, _contratoRetencao, tabela, clickedTr })
        return taxaValida && tipoRefValido;
      });

      setTabelaList(_tabelas);
      handleTabela(_tabelas?.[0]?.id);

      if (clickedTr.selected) {
        const _contratoDisponivel = _tabelasDisponiveis?.find(t => t.contrato === clickedTr.numeroContrato)
        console.log("Refinanciamento.handleFilterTabelas(6-addContrato): ", { tabelasLength: _tabelas.length, _contratoDisponivel })
        if (_tabelas?.length > 0 && !_contratoDisponivel) {
          console.log("Refinanciamento.handleFilterTabelas(6-addContrato): ", clickedTr.numeroContrato)
          _tabelasDisponiveis.push({ contrato: clickedTr.numeroContrato, tabelas: _tabelas.map(t => t.id) });
        }
      }

      console.log("Refinanciamento.handleFilterTabelas(5): ", _tabelasDisponiveis)
      setTabelasDisponiveis(_tabelasDisponiveis);
    }
  }

  const handleContrato = (_contrato, i) => {
    setPrazo(_contrato?.quantidadeDocumentos);

    if (i >= 0) {
      let _contratos = contratos;
      _contratos[i] = { ..._contrato, selected: !_contrato?.selected };
      setClickedTr(_contratos[i]);

      setContratos(_contratos);

      handleFilterConvenios(_contratos[i])

      calcularTotais();
    }
  };

  const calcularTotais = () => {
    let _valor = 0;
    let _selectAll = true;
    for (let i = 0; i < contratos.length; i++) {
      _selectAll = _selectAll && contratos[i].selected;
      if (contratos[i].selected) {
        _valor += parseFloat(contratos[i].valorParcela);
        console.log(
          "Refinanciamento.calcularTotais: ",
          contratos[i].valorParcela
        );
      }
    }

    if (contratos.length <= 0)
      setTabelasDisponiveis([]);

    setSelectAll(_selectAll);
    setValor(parseFloat(_valor).toFixed(2));
  };

  const handleCpf = async (e) => {
    setMsg({ ...msg, cpf: 0 });
    setMsgErro("");
    let nums = /\d/;
    let cpf = e.currentTarget.value;
    let valid = true;
    setContratos([]);
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setCpf(cpf);
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (valid) {
          CarregarDados(cpf);
        } else setMsg({ ...msg, cpf: 1 });
      }

      return false;
    } else if (!e.currentTarget.value) setCpf(null);

    return false;
  };

  const handleTabela = (tabela_id) => {
    console.log("Refinanciamento.handleTabela: ", tabela_id)
    setTabela_id(tabela_id);
    _getPrazos(tabela_id);
  };

  async function submit() {
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];
    let content = {
      prazo,
      tabela_id,
      convenio_id,
      valor: formatFloat(valor),
      tipo: "parcela",
      operacao: "REFINANCIAMENTO",
      contrato: clickedTr.numeroContrato,
      cpf: cpf || clickedTr.numeroCic,
      ip: getCookie({ label: "ip" }),
      latitude,
      longitude,
      proposta_uuid: "",
      dadosRefin: clickedTr,
      agente_id: ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0 ? props.user?.pessoa_id : null,
      operador_id: props.user?.pessoa_id,
      contratos: contratos.filter((c) => c.selected),
    };

    setConsultando(true);

    let _autorizacao = await buscarAutorizacao({ cpf, convenio_id });
    console.log("buscando autorização: ", _autorizacao);
    if (!_autorizacao) {
      setConsultando(false);
      swalError("Não existe autorização para o cliente no convênio informado.");
      return;
    }

    // const allowed = await clienteBloqueado(cpf);
    // if (!allowed) {
    //   setConsultando(false);
    //   swalError({ title: "Operação não poderá ser iniciada, favor entrar em contato com o Banco/Financeira." });
    //   return;
    // }

    sendData({
      uri: uriList("simulador"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setConsultando(false);
        if (res.status && res.status > 200) {
          console.log("Refinanciamento.submit(simulador): ", res);

          let erro
          let msgErro
          try {
            erro = JSON.parse(res.erro || res.message);
            msgErro = String(erro.msg || erro);
          } catch (error) {
            erro = res.erro || res.message;
            msgErro = String(erro);
          }

          console.log("Refinanciamento.submit(simulador-1): ", { erro, msgErro });

          swalError({ title: msgErro });
          // throw new Error();
        } else if (props) {
          props.setClienteDados(contratos);
          props.alterarProposta(res);
          if (props.toggleStep) props.toggleStep(1, content, res);
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: e.message });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  }

  const handleSelectAll = () => {
    let _contratos = contratos;
    let _selectAll = !selectAll;
    for (let i = 0; i < _contratos.length; i++)
      _contratos[i].selected = _selectAll;

    if (!_selectAll)
      setTabelasDisponiveis([]);

    setContratos(_contratos);
    calcularTotais();
  };

  return (
    <fieldset className="col-12" style={{ height: "100%" }}>
      {width() != "mobile" && (
        <h4
          className="display-4 text-center font-weight-bold"
          style={{ marginBottom: "10px", color: "#3E6C84" }}
        >
          Refinanciamento
        </h4>
      )}

      {limpando ? (
        <div style={{ textAlign: "center", width: "100%" }}>
          <br />
          <h2 style={{ opacity: "60%" }}>Limpando dados...</h2>
        </div>
      ) : !carregando && !consultando ? (
        <div className="form" style={{ overflow: "hidden" }}>
          <div className="row flex">
            <div className="form-group col-md-4">
              <InputLabelAlert
                type="tel"
                label="CPF"
                id="cpf"
                value={cpf}
                handle={handleCpf}
                autoComplete="off"
                length="14"
                focus={true}
                onContextMenu={(e) => e.preventDefault()}
              />

              <InputAlert display={msg.cpf} messages={"CPF inválido."} />
            </div>
            <div className="form-group col-md-3">
              <button
                style={{
                  width: "100%",
                  marginTop: width() != "mobile" ? "1.5rem" : "",
                }}
                onClick={limpar}
                // disabled={msgErro}
                className="btn btn-secondary  font-weight-bold"
              >
                Limpar
              </button>
            </div>
          </div>
          {showConsultar && (
            <div className="row">
              <button
                style={{
                  backgroundColor: "#3E6C84",
                  width: width() != "mobile" ? "48%" : "100%",
                }}
                onClick={consultarRefin}
                className="btn btn-md btn-info mb-1  font-weight-bold"
                disabled={contratos && contratos.length > 0}
                visibility={contratos && contratos.length > 0 ? "hidden" : ""}
              >
                Consultar
              </button>
            </div>
          )}
          {/* <div className="row ">
                        <hr style={{ textAlign: "center", width: "100%" }} />
                    </div> */}

          {contratos && contratos.length > 0 ? (
            <div
              // className="mt-2 mb-2 border  pt-1 pl-2 pb-1"
              className="mb-2 border pt-1 pb-1"
              style={{ backgroundColor: "rgba(231, 230, 230,  0.18)" }}
            >
              {/* {<div ><p><strong>Cliente: </strong>{contratos[0].nomePessoa} </p></div>} */}
              <div
                className="form"
                style={{
                  maxHeight: width() == "mobile" ? "100px" : "150px",
                  fontSize: width() == "mobile" ? "x-small" : "small",
                  overflowY: "scroll",
                }}
              >
                <div>
                  Cliente: <strong>{contratos[0].nomePessoa}</strong>
                </div>
                <table
                  // className="table table-bordered table-striped"
                  className="table-hover table-bordered table-responsive"
                  border="1"
                  style={{ cursor: "pointer" }}
                >
                  <thead style={{ position: "sticky", top: "0px" }}>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      {multSelect && (
                        <td
                          className="text-center font-weight-bold"
                          style={{ width: "35px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <input
                              // type="switch"
                              type="checkbox"
                              checked={selectAll}
                              style={{ width: "15px", height: "15px" }}
                              // value={simulacao.selected}
                              onChange={() => handleSelectAll()}
                            />
                          </div>
                        </td>
                      )}

                      <td
                        className="text-left font-weight-bold"
                        style={{ width: "120px" }}
                      >
                        Contrato
                      </td>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "120px" }}
                      >
                        {width() == "mobile" ? "Saldo" : "Saldo Devedor"}
                      </td>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "120px" }}
                      >
                        {width() == "mobile" ? "Parcelas" : "Valor Parcelas"}
                      </td>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "100px" }}
                      >
                        {width() == "mobile" ? "Pagas" : "Parcelas Pagas"}
                      </td>
                      <td
                        className="text-right font-weight-bold"
                        style={{ width: "60px" }}
                      >
                        Taxa
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {contratos &&
                      contratos.map((contrato, i) => (
                        <tr
                          key={i}
                          className={`${clickedTr?.numeroContrato === contrato?.numeroContrato
                            ? "bg-secondary text-light"
                            : ""
                            }`}
                          onClick={() => {
                            handleContrato(contrato, i);
                          }}
                          onDoubleClick={() => {
                            handleContrato(contrato, i);
                          }}
                          // disabled={!contrato?.valido}
                          style={{ textDecoration: !contrato?.valido ? 'line-through' : "", color: !contrato?.valido ? 'red' : "" }}
                          title={!contrato?.valido ? "Contrato não refinanciavel - lista de cessão" : "Clique para selecionar o contrato"}
                        >
                          {multSelect && (
                            <td
                              className="text-center"
                              style={{
                                width: "35px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  // type="switch"
                                  type="checkbox"
                                  checked={contrato?.selected}
                                  style={{ width: "15px", height: "15px" }}
                                  // value={simulacao.selected}
                                  onChange={() => handleContrato(contrato, i)}
                                // disabled={!contrato?.valido}
                                />
                              </div>
                            </td>
                          )}
                          <td id={i} style={{ width: "120px" }}>
                            {
                              (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) < 0)
                                ? contrato?.numeroContrato
                                : '...' + contrato?.numeroContrato?.slice(-3)
                            }
                          </td>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "120px" }}
                          >{`${width() == "mobile" ? "" : "R$"
                            } ${["AGENTE", "OPERADOR PARCEIRO",].indexOf(props.user?.tipo) >= 0 ? '...' : formatCurrency(
                              parseFloat(contrato?.saldoDevedor).toFixed(2)
                            )}`}</td>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "120px" }}
                          >{`${width() == "mobile" ? "" : "R$"} ${parseFloat(
                            contrato?.valorParcela
                          ).toFixed(2)}`}</td>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "100px" }}
                          >{`${contrato?.qtdParcelasPagas}/${contrato?.quantidadeDocumentos}`}</td>
                          <td
                            id={i}
                            className="text-right"
                            style={{ width: "60px" }}
                          >{`${parseFloat(contrato?.taxaNominal).toFixed(
                            2
                          )}%`}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : msgErro ? (
            <h5
              className="text-center border p-2"
              style={{
                color: "red",
                minHeight: width() == "mobile" ? "" : "50px",
              }}
            >
              {msgErro}
            </h5>
          ) : (
            <h5
              className="text-center border p-2"
              style={{
                opacity: "60%",
                minHeight: width() == "mobile" ? "" : "50px",
                fontSize: width() == "mobile" ? "large" : "",
              }}
            >
              Aguardando dados para exibição...
            </h5>
          )}
          <>
            {/* <div className="row mt-0">
                            <hr style={{ textAlign: "center", width: "100%" }} />
                        </div> */}

            <div className="row d-flex ">
              <div className="col-md-7">
                <select
                  className="form-control "
                  defaultValue={convenio_id}
                  onChange={(e) =>
                    handleConvenio(e.currentTarget.value, e.currentTarget)
                  }
                  name="convenio_id"
                  id="convenio_id"
                  style={{
                    backgroundColor: "#EBEEE9",
                    borderRadius: "4px",
                    border: "1.5px solid #727476",
                    backgroundImage:
                      "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                  }}
                >
                  {convenioList ? (
                    convenioList.map((convenio, index) => (
                      <option
                        key={index}
                        title={convenio.sigla}
                        value={convenio.id}
                      >
                        {convenio.nome.toUpperCase()}
                      </option>
                    ))
                  ) : (
                    <option>Carregando</option>
                  )}
                </select>
              </div>

              <div className="col-md-5">
                <select
                  className="form-control "
                  // value={props.data.tabela_id}
                  defaultValue={tabela_id}
                  onChange={(e) => handleTabela(e.currentTarget.value)}
                  name="tabela_id"
                  id="tabela_id"
                  style={{
                    backgroundColor: "#EBEEE9",
                    borderRadius: "4px",
                    border: "1.5px solid #727476",
                    backgroundImage:
                      "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                  }}
                >
                  {tabelaList ? (
                    tabelaList.map((tabela, index) => (
                      <option
                        key={`tabela_id-${tabela.id}`}
                        title={tabela.nome}
                        value={tabela.id}
                      >
                        {tabela.nome}
                      </option>
                    ))
                  ) : (
                    <option>Escolha a Tabela</option>
                  )}
                </select>
              </div>
            </div>

            {/* <div className="row mt-2 col-md-8 flex"> */}
            <div className="row mt-2 col-md-8 flex">
              <label htmlFor="valor">Vlr Parcela(R$)</label>

              <input
                type="tel"
                value={valor}
                style={{
                  marginLeft: "0.3em",
                  width: "120px",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                }}
                // className="custom-input"
                onChange={(e) =>
                  setValor(formatCurrency(e.currentTarget.value))
                }
                name="valor"
                id="valor"
              />
            </div>
            <div className="row mt-2 col-md-8 flex">
              <label htmlFor="prazo">Prazo</label>
              <select
                className="ml-1"
                defaultValue={prazo}
                // className='custom-input'
                name="prazo"
                id="prazo"
                onChange={(e) => setPrazo(e.currentTarget.value)}
                style={{
                  width: "120px",
                  backgroundColor: "#EBEEE9",
                  borderRadius: "4px",
                  border: "1.5px solid #727476",
                  backgroundImage:
                    "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
                }}
              >
                {prazoSteps &&
                  prazoSteps.map((prazo, i) => (
                    <option
                      key={`prazo-${i}`}
                      label={prazo.prazo}
                      value={prazo.prazo}
                    >
                      {prazo.prazo}
                    </option>
                  ))}
              </select>
            </div>
            {/* </div> */}

            <div className="row mt-2 ">
              <button
                style={{
                  backgroundColor: "#3E6C84",
                  width: width() != "mobile" ? "48%" : "100%",
                }}
                onClick={submit}
                disabled={!valor}
                className="btn btn-md btn-info font-weight-bold"
              >
                Avançar
              </button>
            </div>
          </>
        </div>
      ) : !consultando ? (
        <div style={{ textAlign: "center", width: "100%" }}>
          <br />
          <h2
            style={{
              opacity: "60%",
              fontSize: width() == "mobile" ? "large" : "",
            }}
          >
            Carregando dados...
          </h2>
        </div>
      ) : (
        <div style={{ textAlign: "center", width: "100%" }}>
          <br />
          <h2
            style={{
              opacity: "60%",
              fontSize: width() == "mobile" ? "large" : "",
            }}
          >
            Aguarde, fazendo simulação...
          </h2>
        </div>
      )}
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Refinanciamento);
