import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import html2canvas from "html2canvas";

//-----------------------
export const openWithModal = (props) => {
  // render () {
  //   const url = new URL(this.props.href)
  //   if (url.hostname === WEBSITE_HOSTNAME) return (
  //     <a href={this.props.href}>{this.props.children}</a>
  //   )s

  return (
    <>
      <Modal show={true}>
        <iframe src={props.href} />
      </Modal>
    </>
  );
};

const supportDom = () => {
  if (!window.DOMParser) return false;
  var parser = new DOMParser();
  try {
    parser.parseFromString("x", "text/html");
  } catch (err) {
    return false;
  }
  return true;
};

export const getValoresProposta = () => {
  return ["Menor ou igual a 10.000,00", "Maior que 10.000,00"]
}

export const getOrigensProposta = () => {
  return ["Manual", "Importacao"]
}

export const getOperacoes = () => {
  return [
    'CONTRATO NOVO',
    'CREDITO PESSOAL',
    'FGTS',
    'PORTABILIDADE',
    'REFINANCIAMENTO',
    'CARTAO'
  ]
}

export const getStatus = () => {
  return [
    'Aguardando Pagamento',
    'Aguardando Pagto Saldo',
    'Aguardando Refin',
    'Aguardando Saldo',
    'Assinando CCB',
    'Assinando CCB Port',
    'Assinando CCB RefinPort',
    'Autorizando Portabilidade',
    'Averbacao Solicitada',
    'Averbada',
    'Bloqueada',
    'Cancelada',
    'Negada',
    'Pago',
    'Pendente',
    'Portabilidade Autorizada',
    'Portabilidade Averbada',
    'Proposta Assinada',
    'Proposta Port Assinada',
    'Saldo Recebido',
    'Simulacao'
  ]
}

export const exportAsImage = async (el = "root", imageFileName, user) => {
  console.log("general.exportAsImage(0)");
  let [latitude, longitude] = getCookie({ label: "geolocation" })
    ? getCookie({ label: "geolocation" }).split(",")
    : ["", ""];
  console.log("general.exportAsImage(0.1)");
  let jsonGeo = { latitude, longitude, data: new Date(), user };

  console.log("general.exportAsImage(1): ", jsonGeo);
  var element = document.getElementById(el);
  if (element) {
    console.log("general.exportAsImage(2) ");
    element.setAttribute("type", "hidden");
    console.log("general.exportAsImage(2.1) ");
    element.innerHTML =
      "<div><i>" + JSON.stringify(jsonGeo) + "</i></div>" + element.innerHTML;
    console.log("general.exportAsImage(2.2) ");
    let canvas = await html2canvas(element);

    console.log("general.exportAsImage(3) ");
    const imageBase64 = canvas.toDataURL("image/png");

    console.log("general.exportAsImage(4) ");
    //-- Converting base64 image to PNG
    var arr = imageBase64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    console.log("general.exportAsImage(5) ");
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    console.log("general.exportAsImage(6): ", imageFileName);
    return new File([u8arr], imageFileName, { type: mime });
  } else return false;
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

//--------------------
export const Aguarde = (props) => {
  return (
    <Modal
      size={width() == "mobile" ? "sm" : props.size || "md"}
      scrollable
      show={true}
      animation={false}
      backdrop="static"
      centered
      // aria-labelledby="contained-modal-title-vcenter"
      {...props}
    >
      <Modal.Body
        // className="maxWidth750"
        style={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            margin: "10px",
            fontSize: width() == "mobile" ? "small" : "",
          }}
        >
          <Spinner
            animation="border"
            size={width() == "mobile" ? "sm" : "md"}
            style={{ marginRight: "5px", color: "#777575" }}
          />
          {props.legenda || "Aguarde enquanto o processo é finalizado..."}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const LOGRADOUROS =
  "Alameda, Avenida, Chácara, Condomínio, Conjunto, Distrito, Fazenda, Loteamento, Quadra, Residencial, Rua, Setor, Sítio, Via, Viela, Vila, Outros";
export const HORARIOLIST = [
  {
    id: 1,
    descricao: "Manhã (das 8 às 12h)",
    value: "08-12h",
  },
  {
    id: 2,
    descricao: "Tarde (das 13 às 17h)",
    value: "13-17h",
  },
  {
    id: 3,
    descricao: "Noite (das 18 às 20h)",
    value: "18-20h",
  },
];

export const handleFocus = (e) => {
  e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
};

export const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const numerosIndividuais = (valor) => {
  let result = "";
  valor = valor.replace(/[\D]+/g, "");
  for (let chr of valor) {
    if (result.indexOf(chr) < 0) result += chr;
  }

  return result;
};

export const formatFloat = (numero, casas = 2) => {
  if (!numero) {
    numero = "0";
  }
  let valor = numero.toString();

  let result = valor;
  if (valor.indexOf(",") >= 0) {
    result = valor
      .replace(/^(0\.+)(\d)/g, "$2")
      .replace(".", "")
      .replace(",", ".");
  }

  return (result * 1).toFixed(casas);
};

export const primeirosNomes = (nome, qtd) => {
  let result = "";
  if (nome) {
    let _nome = nome.split(" ");
    for (let i = 0; i < qtd; i++)
      if (i < _nome.length) result += " " + _nome[i];

    result = result.trim();
  }
  return result;
};

export const formatCurrency = (val, casas = 2) => {
  // console.log(`FormatCurrency - Valor: ${val}, Casas: ${casas}`);
  let valor;
  if (val) {
    let expressao = /([0-9]{2})$/g;
    let expressaoM = /([0-9]{3}),([0-9]{2}$)/g;
    if (casas > 2)
      switch (casas) {
        case 3:
          expressao = /([0-9]{3})$/g;
          expressaoM = /([0-9]{3}),([0-9]{3}$)/g;
          break;
        case 4:
          expressao = /([0-9]{4})$/g;
          expressaoM = /([0-9]{3}),([0-9]{4}$)/g;
          break;
        case 5:
          expressao = /([0-9]{5})$/g;
          expressaoM = /([0-9]{3}),([0-9]{5}$)/g;
          break;
        default:
          break;
      }
    expressao = /([0-9]{2})$/g;
    expressaoM = /([0-9]{3}),([0-9]{2}$)/g;

    valor = val.toString();
    valor = valor + "";
    valor = valor.replace(/[\D]+/g, "");
    valor = valor + "";
    valor = valor.replace(expressao, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(expressaoM, ".$1,$2");
    }

    if (valor.indexOf("0.") >= 0) valor = valor.replace(/^(0\.+)(\d)/g, "$2");
  }

  return valor;
};

export const mCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return cpf;
};

export const mDate = (v) => {
  if (v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");

    v = v.replace(/(\d{2})(\d{2})$/, "$1$2");
  }

  return v;
};

export const mDateBD = (v) => {
  if (v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$2-$1");
  }

  return v;
};

export const mCEP = (cep) => {
  cep = cep.replace(/\D/g, "");
  cep = cep.replace(/^(\d{5})(\d)/, "$1-$2");

  return cep;
};

export const mTel = (v) => {
  // console.log("mTel")
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
  // v = v.replace(/\D/g, "");
  // // v = v.replace(/^(\d{2})(\d)/g, "($1)$2");
  // v = v.replace(/(\d)(\d{4})$/, "$1-$2");

  // return v;
};

export const mDDD = (v) => {
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})$/, "$1");
  if (v.length === 2) v = "0" + v;
  return v;
};

export const compareDate = (d1, d2) => {
  d1 = d1.toLocaleDateString();
  d2 = d2.toLocaleDateString();

  d1 = d1.split("/");
  d2 = d2.split("/");

  if (d1[2] === d2[2]) {
    if (d1[1] === d2[1]) {
      if (d1[0] >= d2[0]) return true;
      return false;
    } else if (d1[1] < d2[2]) {
      return false;
    }

    return true;
  } else if (d1[2] < d2[2]) {
    return false;
  }

  return true;
};

export const width = (allSizes = false, _width = false) => {
  let w = window.innerWidth;
  let result = "desktop"

  // console.log("Largura da tela(innerWidth):", w)
  if (w < 1000) result = "medium";
  if (w < 800) result = "mobile";
  if (w < 600 && allSizes) result = "mobile-small";
  if (w < 400 && allSizes) result = "mobile-xsmall";

  // console.log("general.width", { w, result });
  return _width ? w : result;
};

export const createNewCookie = ({ label, content }) => {
  document.cookie = `${label}=${content}; expires=Thu, 18 Dec 2050 12:00:00 UTC`;
};

export const getLocation = () => {
  let result = { latitude: 0, longitude: 0 };
  saveCookie({ label: "geolocation", content: "" });
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log("position(0)", position.coords);
      result = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      saveCookie({
        label: "geolocation",
        content: [position.coords.latitude, position.coords.longitude],
      });
    });
  }

  console.log("position(1)", result);
  return result;
};

export const getCookie = ({ label }) => {
  let cookies = document.cookie;

  cookies = cookies.split(";");

  for (let cookie of cookies) {
    if (cookie.indexOf(label) !== -1) {
      let actual = cookie.split("=");

      return actual[1].trim();
    }
  }

  return false;
};

const setOrder = (name, field, type, order) => {
  const _data = {
    field: field,
    type: type,
    order: order,
  };

  localStorage.setItem("_order" + name, JSON.stringify(_data));
};

const getOrder = (name) => {
  return JSON.parse(localStorage.getItem("_order" + name))
    ? JSON.parse(localStorage.getItem("_order" + name))
    : { order: "asc" };
};

export const DefaultOrder = (name, arr, setArr) => {
  const _order = getOrder(name);
  sortArrObj(name, _order.field, _order.type, arr, setArr, false);
};

export const sortArrObj = (name, key, type, arr, setArr, update = true) => {
  let _arr = [...arr];
  //  console.log(arr)
  _arr = _arr.sort((a, b) => {
    update &&
      setOrder(
        name,
        key,
        type,
        getOrder(name).order === "desc" ? "asc" : "desc"
      );
    if (type === "string") {
      if (getOrder(name).order === "asc") {
        return sortByString(a, b, key);
      } else {
        return sortByString(b, a, key);
      }
    } else if (type === "numeric") {
      if (getOrder(name).order === "asc") {
        return sortByNumber(a, b, key);
      } else {
        return sortByNumber(b, a, key);
      }
    } else {
      if (getOrder(name).order === "asc") {
        return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
      } else {
        return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
      }
    }
  });
  //console.log(_arr)
  setArr(_arr);
};

export const saveCookie = ({ label, content }) => {
  console.log("saveCookie", { label, content })
  createNewCookie({ label, content });

  return false;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const sortByString = (a, b, key) => {
  let aVal = a[key] || "z",
    bVal = b[key] || "z";

  aVal = aVal.toUpperCase();
  bVal = bVal.toUpperCase();

  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }
  return 0;
};

export const sortByNumber = (a, b, key) => {
  let aVal = a[key] || 0,
    bVal = b[key] || 0;

  aVal = parseFloat(aVal.toString().replace(/\D/g, ""));
  bVal = parseFloat(bVal.toString().replace(/\D/g, ""));

  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }
  return 0;
};

export const operacaoList = [
  {
    descricao: "Contrato Novo",
    value: "CONTRATO NOVO",
  },
  {
    descricao: "Refinanciamento",
    value: "REFINANCIAMENTO",
  },
  {
    descricao: "Portabilidade",
    value: "PORTABILIDADE",
  },
  {
    descricao: "Cartão",
    value: "CARTAO",
  },
  {
    descricao: "Crédito Pessoal",
    value: "CREDITO PESSOAL",
  },
  {
    descricao: "FGTS",
    value: "FGTS",
  },
];

export const usuarios = [
  "cliente",
  "agente",
  "operador",
  'operador parceiro',
  "gerente",
  "administrador",
  "sistema",
];

export const getIdCode = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const contextMenuOptions = [
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Reapresentar TED",
    icon: "mdi mdi mdi-format-list-checks",
    action: "reapresentarted",
    colorClass: "text-primary",
    enabled: true,
    positions: ["pago"],

    tip: "Reapresentar TED de pagamento do cliente",
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE"],
    title: "Enviar proposta para Assinatura",
    icon: "mdi mdi-email-outline",
    action: "enviarParaAssinatura",
    colorClass: "text-success",
    positions: ["cadastrada no banco", "erro ao assinar ccb"],
    enabled: true,
    tip: "Enviar proposta para o Cliente assinar",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      console.log("general.enviarParaAssinatura(props)", props.valor_liquido);
      return (
        (enabled ||
          (props?.posicao?.toLowerCase() == "simulacao" &&
            props.gravou_documentos && props?.operacao?.toLowerCase() != 'portabilidade')) &&
        (!props.valor_liquido || props.valor_liquido > 0)
      );
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Solicitar Averbação",
    icon: "mdi mdi-skew-more",
    action: "solicitarAverbacao",
    colorClass: "text-primary",
    enabled: true,
    positions: [
      "proposta assinada",
      "averbacao solicitada",
      "aguardando deferimento",
    ],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],

    multSelect: true,
    tip: "Solicitar Averbação da Proposta",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(solicitarAverbacao): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = [
          "proposta assinada",
          "averbacao solicitada",
          "aguardando deferimento",
          "aguardando refin",
          "proposta port assinada"
        ]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && (['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
      }
      else
        return (
          props.tipo_convenio != "ORIGINAL" &&
          ((props.tipo_convenio?.toLowerCase() == "inss" &&
            ["aguardando refin"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
            ['administrador', 'operador'].indexOf(props?.user?.tipo.toLowerCase()) >= 0) ||
            (props.tipo_convenio?.toLowerCase() != "inss" &&
              ["proposta port assinada"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
              ['administrador', 'operador'].indexOf(props?.user?.tipo.toLowerCase()) >= 0)
            || enabled
          )
        );
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Enviar para Banco - Cadastro",
    icon: "mdi mdi-skew-more",
    action: "enviarParaBancoCadastro",
    colorClass: "text-primary",
    enabled: true,
    positions: ["em analise", "simulacao"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Enviar proposta para cadastro no banco",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return props.tipo_convenio == "SICOOB" && enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Solicitar Saldo Devedor",
    icon: "mdi mdi-bank",
    action: "solicitarSaldoDevedor",
    colorClass: "text-primary",
    enabled: true,
    positions: ["portabilidade autorizada", "erro ao solicitar saldo"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    multSelect: true,
    tip: "Solicitar Saldo devedor da Proposta",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(solicitarSaldoDevedor): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["portabilidade autorizada", "erro ao solicitar saldo"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else {
        const posicao = props?.posicao || props?.detalhes?.posicao;
        const positions = ["portabilidade autorizada", "erro ao solicitar saldo"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }

    },
  },
  // {
  //   disableUser: ["AGENTE", "CLIENTE"],
  //   title: "Solicitar Autorização de Portabilidade",
  //   icon: "mdi mdi-checkbox-marked-outline",
  //   action: "solicitarAutorizacaoPortabilidade",
  //   colorClass: "text-primary",
  //   enabled: true,
  //   positions: ["simulacao"],
  //   tip: "Solicitar Autorização de Portabilidade",
  //   id: getIdCode(),
  //   enableFunction: (props, enabled, filtros) => {
  //     console.log("general.contextMenuOptions(solicitarAutorizacaoPortabilidade): ", props)
  //     return (
  //       (["simulacao"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
  //         props.tipo_convenio == "INSS" &&
  //         props.operacao == 'PORTABILIDADE' &&
  //         props.gravou_documentos
  //       ) && enabled
  //     );
  //   },
  // },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Checar Autorização",
    icon: "mdi mdi-checkbox-marked-outline",
    action: "autorizarPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    positions: ["autorizando portabilidade"],
    tip: "Checar autorização de portabilidade",
    multSelect: true,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(autorizarPortabilidade): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["simulacao", "autorizando portabilidade"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return (
          (["simulacao", "autorizando portabilidade"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
            props.tipo_convenio == "INSS" &&
            props.operacao == 'PORTABILIDADE' &&
            props.gravou_documentos
          )
        );
    },
  },
  {
    title: "Consultar Solicitação de Saldo",
    icon: "mdi mdi-file-find",
    action: "consultarSolicitacaoSaldo",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando saldo"],
    tipoUsuarioEx: ["agente", "cliente"],
    multSelect: true,
    tip: "Consultar Solicitação de Saldo Devedor",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(consultarSolicitacaoSaldo): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["aguardando saldo"]
        console.log("general.contextMenuOptions(consultarSolicitacaoSaldo-1): ", { filtros })
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return enabled
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Resolicitar Saldo Devedor",
    icon: "mdi mdi-bank",
    action: "solicitarSaldoDevedor",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando saldo"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],

    tip: "Refazer Solicitação de Saldo devedor da Proposta",
    id: getIdCode(),
  },

  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO"],
    title: "Solicitar Pagto Saldo",
    icon: "mdi mdi-cached",
    action: "pagarPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    multSelect: true,
    tip: "Solicitar o Pagamento do Saldo Devedor",
    id: getIdCode(),
    checklist: true,
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(pagarPortabilidade): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const convenio = filtros?.convenio
        return ((posicao?.toLowerCase() == "portabilidade averbada" &&
          convenio != "INSS") ||
          (posicao?.toLowerCase() == "proposta port assinada" &&
            convenio == "INSS")
        ) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else

        return (
          enabled &&
          ((props.posicao?.toLowerCase() == "portabilidade averbada" &&
            props.tipo_convenio != "INSS") ||
            (props.posicao?.toLowerCase() == "proposta port assinada" &&
              props.tipo_convenio == "INSS")
          )
        );
    },

  },
  {
    disableUser: ["CLIENTE", "OPERADOR PARCEIRO"],
    title: "Consultar Status Portabilidade",
    icon: "mdi mdi-file-find",
    action: "consultarStatusPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando pagto saldo"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    multSelect: true,
    tip: "Consultar Status da Portabilidade no Banco",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(consultarStatusPortabilidade): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["aguardando pagto saldo"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else

        return enabled
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Gerar Refin Automático",
    icon: "mdi mdi-file-find",
    action: "gerarPropostaRefinPort",
    colorClass: "text-primary",
    enabled: true,
    positions: ["portabilidade averbada"],
    tipoUsuarioEx: ["agente", "cliente"],
    multSelect: true,
    tip: "Gerar Proposta de Refinanciamento da(s) Portabilidade(s)",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(consultarStatusPortabilidade): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["portabilidade averbada"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador', 'operador parceiro'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return enabled
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Simular Proposta Refin",
    icon: "mdi mdi-send",
    action: "assinarRefinPort",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando refin"],
    tipoUsuarioEx: ["agente", "cliente"],

    tip: "Enviar Proposta de Refin da Port para Assinatura",
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE"],
    title: "Simular Portabilidade",
    icon: "mdi mdi-calculator",
    action: "simularPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    positions: ["saldo recebido"],
    tipoUsuarioEx: ["cliente", "agente",],
    tip: "Simulação da Portabilidade após chegada do saldo devedor",
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Averbação Manual",
    icon: "mdi mdi-stamper",
    action: "averbar",
    colorClass: "text-primary",
    enabled: true,
    positions: [
      "proposta assinada",
      "averbacao solicitada",
      "aguardando deferimento",
    ],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],

    tip: "Averbar proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      // console.log("general.contextMenuOptions - enableFunction: ", props)
      return (
        props.tipo_convenio != "ORIGINAL" &&
        ((props.tipo_convenio?.toLowerCase() == "inss" &&
          ["aguardando refin"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
          ['administrador', 'operador'].indexOf(props?.user?.tipo.toLowerCase()) >= 0) ||
          (props.tipo_convenio?.toLowerCase() != "inss" &&
            ["proposta port assinada"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
            ['administrador', 'operador'].indexOf(props?.user?.tipo.toLowerCase()) >= 0)
          || enabled
        )
      );
    },

    // enableFunction: (props, enabled) => {
    //   console.log("props", props.user);
    //   return (
    //     enabled &&
    //     props.tipo_convenio != "ORIGINAL" &&
    //     props.pagamento == "AUTOMATICO" &&
    //     ((props.tipo_convenio?.toLowerCase() == "inss" &&
    //       ["aguardando refin", 'proposta assinada'].indexOf(props.posicao?.toLowerCase()) > 0) ||
    //       (props.tipo_convenio?.toLowerCase() != "inss" &&
    //         ["proposta port assinada", 'proposta assinada'].indexOf(props.posicao?.toLowerCase()) > 0)
    //       // || enabled
    //     ) &&
    //     (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    //   );
    // },
  },
  {
    title: "Checar Assinatura",
    icon: "mdi mdi-pencil",
    action: "informarAssinatura",
    colorClass: "text-primary",
    enabled: true,
    // borderBottom: true,
    positions: [
      "assinando ccb",
      "assinando ccb refinport",
      "assinando ccb port",
    ],
    multSelect: true,
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Verificar se proposta foi assinada",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(informarAssinatura): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = [
          "assinando ccb",
          "assinando ccb refinport",
          "assinando ccb port",
        ]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else

        return enabled
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Enviar para Banco",
    icon: "mdi mdi-arrow-right",
    action: "enviarParaBanco",
    colorClass: "text-primary",
    enabled: true,
    positions: ["averbada", "negada"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Enviar proposta com dossiê para aprovação e pagamento no banco",
    multSelect: true,
    id: getIdCode(),
    checklist: true,
    enableFunction: (props, enabled, filtros) => {
      console.log("enableFuncion", props, enabled);
      // console.log("general.contextMenuOptions(enviarParaBanco): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["averbada", "negada"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return (
          (["ORIGINAL", "SICOOB"].indexOf(props.tipo_convenio) >= 0 &&
            props.posicao?.toLowerCase() == "proposta assinada") ||
          enabled
        );
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Status Banco",
    icon: "mdi mdi-file-find",
    action: "consultarStatusBanco",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando pagamento"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Consultar status da Proposta no Banco",
    multSelect: true,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(consultarStatusBanco): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["aguardando pagamento"]
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return (!props.pagamento || props.pagamento == "AUTOMATICO") && enabled;
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Averbação",
    icon: "mdi mdi-file-find",
    action: "consultarAverbacao",
    colorClass: "text-primary",
    enabled: true,
    positions: ["averbada", "averbacao solicitada"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Consultar Averbação(Gerar Comprovante)",
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Reenviar dossiê para Banco",
    icon: "mdi mdi-arrow-right",
    action: "reenviarDossie",
    colorClass: "text-primary",
    multSelect: true,
    enabled: true,
    positions: ["aguardando pagamento", "aguardando pagto saldo", "pago", 'portabilidade averbada', 'aguardando refin'],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Reenviar dossiê do contrato para o Banco",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(pendenciar): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao
        const positions = ["aguardando pagamento", "aguardando pagto saldo", "pago", 'portabilidade averbada', 'aguardando refin']
        return (positions.indexOf(posicao?.toLowerCase()) >= 0) && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return (!props.pagamento || props.pagamento == "AUTOMATICO") && enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Reenviar contrato para Banco",
    icon: "mdi mdi-arrow-right",
    action: "reenviarParaBanco",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando pagamento", "aguardando pagto saldo"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Reenviar contrato para o Banco",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (!props.pagamento || props.pagamento == "AUTOMATICO") && enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Informar Pagamento",
    icon: "mdi mdi-cash-100",
    action: "informarPagamento",
    colorClass: "text-primary",
    enabled: true,
    // borderBottom: true,
    positions: ["aguardando pagamento"],
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    tip: "Informar pagamento da proposta pelo banco",
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Pendenciar",
    icon: "mdi mdi-clock",
    action: "pendenciar",
    // action: "analiseAutomatica",
    enabled: true,
    colorClass: "text-primary",
    multSelect: true,
    positionsEx: [
      "pendente",
      "pago",
      "cancelada",
      "bloqueada",
      "aguardando pagamento",
      "negada",
    ],
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Pendenciar proposta",
    borderTop: true,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {

      if (props?.multSelect?.length > 1) {
        return ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else {
        const _positionsExOpParceiro = [
          "cancelada",
          "pago",
          "negada",
          "aguardando pagamento",
          "aguardando refin",
          "aguardando pagto saldo",
          "bloqueada",
          "aguardando pagamento de saldo",
          "portabilidade averbada",
          "averbada",
          "averbacao solicitada",
        ]

        console.log("general.contextMenuOptions(pendenciar): ", { enabled, tipoUsuario: props?.user?.tipo?.toLowerCase(), posicao: props.posicao.toLowerCase() })
        if (props?.user?.tipo?.toLowerCase() == 'operador parceiro')
          return (enabled && _positionsExOpParceiro.indexOf(props.posicao.toLowerCase()) < 0)
        else
          return (enabled && (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0))
      }
    },

  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Despendenciar",
    icon: "mdi mdi-clock",
    action: "despendenciar",
    enabled: true,
    colorClass: "text-primary",
    positions: ["pendente"],
    tipoUsuarioEx: ["agente", 'operador parceiro'],
    tip: "Despendenciar proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      console.log("general.enableFunction(despendenciar): ", { enabled, pendenciatmp: props.pendenciatmp })
      // return (enabled && props.pendenciatmp)
      return (enabled)
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO"],
    title: "Bloquear",
    icon: "mdi mdi-lock",
    action: "bloquear",
    enabled: true,
    colorClass: "text-danger",
    positionsEx: [
      "bloqueada",
      "pago",
      "cancelada",
      "simulacao",
      "aguardando pagamento",
      "proposta assinada",
      "negada",
    ],
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Bloquear proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (enabled && (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0))
    },

  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO"],
    title: "Desbloquear",
    icon: "mdi mdi-lock-open",
    action: "desbloquear",
    colorClass: "text-success",
    enabled: true,
    borderBottom: true,
    positions: ["bloqueada"],
    tipoUsuarioEx: ["agente", "operador", 'operador parceiro'],
    tip: "Desbloquear proposta",
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Cancelar",
    icon: "mdi mdi-close-circle",
    action: "cancelar",
    colorClass: "text-danger",
    multSelect: true,
    enabled: true,
    borderTop: true,
    positionsEx: [
      "cancelada",
      "pago",
      "negada",
      "aguardando pagamento",
      "aguardando refin",
      "aguardando pagto saldo",
      "bloqueada",
      "aguardando pagamento de saldo",
      "portabilidade averbada",
      "averbada",
      "averbacao solicitada",
    ],

    tipoUsuarioEx: ["agente", 'cliente'],
    tip: "Cancelar proposta",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      console.log("general.enableFunction: ", { props, enabled });
      // console.log("general.contextMenuOptions(cancelar): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        return ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0
      }
      else
        return (enabled || (props?.posicao?.toLowerCase() != "bloqueada" &&
          props?.posicao?.toLowerCase() != "cancelada" &&
          ['administrador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0))
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR", 'OPERADOR PARCEIRO'],
    title: "Inutilizar",
    icon: "mdi mdi-shuffle-disabled",
    action: "inutilizar",
    colorClass: "text-danger",
    enabled: true,
    borderTop: true,
    positionsEx: ["inutilizada", "pago", "negada", 'aguardando refin'],
    tip: "Inutilizar proposta(A Proposta não ficará mais visível)",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (enabled || ['administrador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Alterar Valores",
    icon: "mdi mdi-calculator",
    action: "simulation",
    enabled: true,
    borderBottom: true,
    colorClass: "text-success",
    positions: [
      "simulacao",
      "portabilidade autorizada",
      "autorizando portabilidade",
      "aguardando saldo"
    ],
    // positionsEx: [
    //   "bloqueada",
    //   "pago",
    //   "cancelada",
    //   "aguardando pagamento",
    //   "proposta assinada",
    //   "proposta port assinada",
    //   "negada",
    //   "saldo recebido",
    // ],
    tip: "Alterar Valores da Proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (enabled || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    },

  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Enviar proposta para o Cliente",
    icon: "mdi mdi-email-outline",
    action: "emailCliente",
    colorClass: "text-success",
    positions: [
      "assinando ccb",
      "assinando ccb port",
      "assinando ccb refinport",
      "cadastrada no banco",
    ],
    positionsEx: ["cadastrada no banco"],
    enabled: true,
    tip: "Enviar proposta para o Cliente",
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Enviar proposta para o Cliente(Whats)",
    icon: "mdi mdi-whatsapp",
    action: "enviarparaassinaturawhats",
    colorClass: "text-success",
    positions: [
      "assinando ccb",
      "assinando ccb port",
      "assinando ccb refinport",
      "erro ao assinar ccb",
    ],
    enabled: true,
    tip: "Enviar proposta para o Cliente(Token via WhatsApp)",
    id: getIdCode(),
  },
  // {
  //   disableUser: ["AGENTE", "CLIENTE"],
  //   title: "Enviar Email",
  //   icon: "mdi mdi-email-outline",
  //   action: "enviarEmail",
  //   colorClass: "text-success",
  //   enabled: true,
  //   borderBottom: true,
  //   positionsEx: ["cancelada", "bloqueada", "negada"],

  //   tip: "Enviar email referente a Proposta",
  //   id: getIdCode(),
  //   enableFunction: (props, enabled) => {
  //     return (enabled && (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0))
  //   },

  // },
  {
    disableUser: ["CLIENTE"],
    title: "Documentos",
    icon: "mdi mdi-file-document",
    action: "gerirDocs",
    enabled: true,
    // borderTop: true,
    colorClass: "text-success",
    // positionsEx: ["cancelada"],
    // tipoUsuarioEx: ["agente"],
    tip: "Bloquear proposta",
    id: getIdCode(),
  },
  // {
  //   disableUser: ["CLIENTE"],
  //   title: "Espelhar",
  //   icon: "mdi mdi-content-copy",
  //   action: "clonar",
  //   colorClass: "text-primary",
  //   enabled: true,
  //   borderBottom: true,
  //   tipoUsuarioEx: ["agente", "cliente"],
  //   tip: "Espelhar/Copiar a proposta atual, gerando uma nova",
  //   id: getIdCode(),
  //   enableFunction: (props, enabled) => {
  //     return (enabled && (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0))
  //   },

  // },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Margem",
    icon: "mdi mdi-cart-plus",
    action: "consultarMargem",
    colorClass: "text-primary",
    borderTop: true,
    enabled: true,
    tip: "Consultar margem consignável do cliente",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return props.tipo_convenio != "ORIGINAL" && enabled;
    },
  },
  {
    title: "Alterar Simulação",
    icon: "mdi mdi-update",
    action: "alterarSimulacao",
    colorClass: "text-primary",
    enabled: true,
    tip: "Alterar Simulação do Banco Original",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return props.tipo_convenio == "ORIGINAL" && enabled;
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Restrições",
    icon: "mdi mdi-chemical-weapon",
    action: "consultarRestricoes",
    colorClass: "text-primary",
    enabled: true,

    tip: "Consultar restrições do cliente no banco",
    id: getIdCode(),
  },
  {
    title: "Status",
    icon: "mdi mdi-white-balance-incandescent",
    action: "status",
    colorClass: "text-primary",
    enabled: true,
    borderBottom: true,
    tip: "Visualização do Status da Proposta",
    id: getIdCode(),
  },
  {
    title: "Auditoria de Assinatura",
    icon: "mdi mdi-filmstrip",
    action: "auditAssinatura",
    colorClass: "text-primary",
    positionsEx: [
      "inutilizada"
    ],
    enabled: true,
    borderBottom: true,
    tip: "Visualização da Auditoria de Assinatura",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return enabled && props?.posicao?.toLowerCase() != "simulacao"
    },
  },
  {
    title: "Observações",
    icon: "mdi mdi mdi-note",
    action: "obs",
    colorClass: "text-primary",
    enabled: true,
    // positionsEx: ["cancelada", "bloqueada", "negada"],

    tip: "Observações da Proposta",
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Conformidade",
    icon: "mdi mdi mdi-format-list-checks",
    action: "conformidade",
    colorClass: "text-primary",
    enabled: true,
    // positionsEx: ["cancelada", "bloqueada", "negada"],
    positionsEx: ["bloqueada", "negada"],

    tip: "Conformidade da Proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      console.log("general.Conformidade(enableFunction)", { enabled, detalhes: props })
      return enabled && props?.posicao?.toLowerCase() != "simulacao";
    },
  },
  {
    title: "Histórico de Alterações",
    icon: "mdi mdi-history",
    action: "log",
    colorClass: "text-primary",
    enabled: true,
    borderBottom: true,
    tip: "Histórico de alterações da proposta",
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Auditoria Bancária",
    icon: "mdi mdi-file-tree",
    action: "auditBanc",
    colorClass: "text-primary",
    enabled: true,
    tip: "Auditoria/Histórico de chamadas à API do banco",
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO", "OPERADOR"],
    title: "Voltar Status da Proposta",
    icon: "mdi mdi-arrange-send-backward",
    action: "voltarStatusProposta",
    colorClass: "text-primary",
    enabled: true,
    // tipoUsuario: ["administrador"],
    tip: "Volta o status da proposta para o status anterior",
    id: getIdCode(),
    // enableFunction: (props, enabled) => {
    //   console.log("EnableFunction(voltar status): ", { enabled, props })
    //   console.log("EnableFunction(voltar status-1): ", props.user?.tipo?.toLowerCase())
    //   console.log("EnableFunction(voltar status-2): ", props.user?.tipo?.toLowerCase() !== 'administrador')
    //   return enabled
    //   // return (enabled && ['administrador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    // },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Integrar com Procrédito",
    icon: "mdi mdi-bank",
    action: "processarProcredito",
    colorClass: "text-primary",
    enabled: true,
    positions: ["portabilidade autorizada", "erro ao solicitar saldo"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    multSelect: true,
    tip: "Fazer integração da proposta com o sistema Procrédito",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      let result = props?.multSelect?.length > 1
      if (result) {
        const operationsEx = ["portabilidade"]
        const positions = ["pago"]
        return (positions.indexOf(filtros?.posicao?.toLowerCase()) >= 0) && (['administrador', 'operador'].indexOf(props?.user?.tipo?.toLowerCase()) >= 0) &&
          (operationsEx.indexOf(props?.operacao?.toLowerCase()) < 0)
      }
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Alterar Proposta",
    icon: "mdi mdi-update",
    action: "alteracaoProposta",
    colorClass: "text-primary",
    enabled: true,
    tip: "Alterar dados da proposta",
    tipoUsuarioEx: ["agente", "cliente", 'operador parceiro'],
    positionsEx: ["pendente", "pago", "bloqueada", "negada"],
    id: getIdCode(),
    // enableFunction: (props, enabled) => {
    //   return (
    //     enabled &&
    //     (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    //   );
    // },
  },
];

export const getTiposDocumentos = (user) => {
  let _tipos = [
    {
      key: 1,
      value: "d_pessoal",
      nome: "Documento Pessoal",
      anexavel: true
    },
    {
      key: 2,
      value: "d_pessoalv",
      nome: "Documento Pessoal - Verso",
      anexavel: true
    },
    {
      key: 3,
      value: "contracheque",
      nome: "ContraCheque",
      anexavel: true
    },
    {
      key: 4,
      value: "c_bancario",
      nome: "Comprovante Bancário",
      anexavel: true
    },
    {
      key: 5,
      value: "c_endereco",
      nome: "Comprovante de Endereço",
      anexavel: true
    },
    {
      key: 7,
      value: "outros",
      nome: "Outros",
      anexavel: true
    },
    {
      key: 8,
      value: "c_averbacao",
      nome: "Comprovante de Averbação",
      anexavel: true
    },
    {
      key: 9,
      value: "ded",
      nome: "Documento de Evolução da Dívida",
      anexavel: true
    },
    {
      key: 10,
      value: "ccb_assinada",
      nome: "CCB Assinada",
    },
    {
      key: 11,
      value: "doc_unificado",
      nome: "Documento Unificado para Assinatura",
    },
    {
      key: 12,
      value: "def_averbacao",
      nome: "Deferimento de Averbação",
      anexavel: true
    },
    {
      key: 13,
      value: "ccb",
      nome: "CCB",
    },
    {
      key: 14,
      value: "ccb_port_assinada",
      nome: "CCB Port Assinada",
    },
    {
      key: 15,
      value: "ccb_refinport_assinada",
      nome: "CCB Refin Port Assinada",
    },
  ]

  if (['ADMINISTRADOR', 'OPERADOR'].indexOf(user?.tipo) >= 0)
    _tipos.push({
      key: 6,
      value: "selfie",
      nome: "Selfie",
    },)

  return _tipos.sort((a, b) => a.key - b.key)
}

// export const handleGetUrlFromBase64 = (base64String) => {
//   // Decodifica a string Base64 para uma string binária
//   const binaryString = window.atob(base64String);

//   // Converte a string binária em um array de bytes
//   const bytes = new Uint8Array(binaryString.length);
//   for (let i = 0; i < binaryString.length; i++) {
//     bytes[i] = binaryString.charCodeAt(i);
//   }

//   // Cria um Blob com o array de bytes
//   const blob = new Blob([bytes], { type: 'application/octet-stream' });

//   // Cria e retorna a URL do Blob
//   const url = URL.createObjectURL(blob);
//   return url;
// };

export function handleGetUrlFromBase64(base64String, filename = 'file') {
  const binaryString = window.atob(base64String);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: 'application/pdf' }); // Supondo que é um PDF
  const url = URL.createObjectURL(blob);

  // Aqui você retorna não apenas a URL, mas também um nome para o arquivo
  return url
}