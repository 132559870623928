import React, { useState, useEffect } from "react";
import { sendData, getData } from "../../../helpers/auth";
import { InputLabelAlert, InputAlert } from "../../layout";
import { formatCurrency } from "../../../helpers/general";
import { uriList } from "../../../helpers/config";
import Swal, { swalError } from "../../../components/swal";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Buttons } from "../../../components/layout";

const Form = ({ ...props }) => {
  const [contratos, setContratos] = useState(null);
  const [clickedTr, setClickedTr] = useState({});
  const [carregando, setCarregando] = useState(true);
  const [naoEnviar, setNaoEnviar] = useState(false);

  useEffect(() => {
    console.log("SelPort: ", props.cpf);

    async function _buscarContratosPort() {
      if (props.exibir_contratos_port) {
        await sendData({
          uri: uriList("buscarContratosPort"),
          content: { cpf: props.cpf },
          method: "POST",
        })
          .then((res) => {
            setCarregando(false);
            if (res.status && parseInt(res.status) > 200) throw new Error();
            setContratos(res.data);
          })
          .catch((e) => {
            setClickedTr(false);
            swalError({
              title:
                "Erro ao buscar contratos de Portabilidade, não será possível gravar Proposta!",
            });
          });
      } else setCarregando(false);
    }

    _buscarContratosPort();
  }, []);

  function handleContrato(_contrato) {
    if (!_contrato) return;

    setClickedTr(_contrato);
  }

  function handleNrContrato(contrato, index) {
    console.log("selPort.handleNrContrato(contrato): ", contrato)
    console.log("selPort.handleNrContrato(index): ", index)
  }


  function goBack() {
    if (props.goBack) props.goBack();
  }

  function submit() {
    if (props.submit) props.submit(clickedTr);
  }

  return (
    <>
      <Modal
        size={"lg"}
        scrollable
        show={true}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Body
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {carregando && (
            <div style={{ textAlign: "center", width: "100%" }}>
              <br />
              <h2 style={{ opacity: "60%" }}>Aguarde, carregando dados...</h2>
            </div>
          )}
          {(!carregando && !props.exibir_contratos_port && (
            <>
              <div className="form-group">
                <div style={{ pointerEvents: "none", textAlign: "center" }}>
                  <h1>Dados da Portabilidade</h1>
                </div>
                <hr className="mt-1" />
              </div>

              <div className="row">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="CNPJ do Banco"
                    id="nrCNPJ"
                    value={clickedTr.nrCnpj}
                    handle={(e) => {
                      setClickedTr({ ...clickedTr, nrCnpj: e.target.value });
                    }}
                    focus={true}
                  />
                </div>
                <div className="form-group col-md-6">
                  <InputLabelAlert
                    type="text"
                    label="Nome do Banco"
                    id="nmConsignataria"
                    value={clickedTr.nmConsignataria}
                    handle={(e) => {
                      setClickedTr({
                        ...clickedTr,
                        nmConsignataria: e.target.value,
                      });
                    }}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Contrato"
                    id="contrato"
                    value={clickedTr.nrContrato}
                    handle={(e) => {
                      setClickedTr({
                        ...clickedTr,
                        nrContrato: e?.target?.value?.replace(/\D/g, ""),
                      });
                    }}
                    focus={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Parcela Portada"
                    id="vlParcelaPort"
                    cifrao={true}
                    value={formatCurrency(clickedTr.vlParcelaPort)}
                    handle={(e) => {
                      setClickedTr({
                        ...clickedTr,
                        vlParcelaPort: formatCurrency(e.target.value),
                      });
                    }}
                    msgErro={"É necessário informar o Valor da Parcela"}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Orgao"
                    id="codOrgao"
                    value={clickedTr.codOrgao}
                    handle={(e) => {
                      setClickedTr({ ...clickedTr, codOrgao: e.target.value });
                    }}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Convênio"
                    id="cdConvenio"
                    value={clickedTr.cdConvenio}
                    handle={(e) => {
                      setClickedTr({
                        ...clickedTr,
                        cdConvenio: e.target.value,
                      });
                    }}
                    focus={false}
                  />
                </div>
              </div>
            </>
          )) ||
            (props.exibir_contratos_port && (
              <fieldset className="col-12">
                <h4
                  className="display-4 text-center font-weight-bold"
                  style={{ marginBottom: "15px", color: "#3E6C84" }}
                >
                  Seleção de Contrato
                </h4>
                <div className="form-group col-md-12">
                  <table border="1" style={{ cursor: "pointer" }}>
                    <thead style={{ position: "sticky", top: "0px" }}>
                      <tr style={{ backgroundColor: "#f2f2f2" }}>
                        <td
                          className="text-left font-weight-bold"
                          style={{ width: "120px" }}
                        >
                          Contrato
                        </td>
                        <td
                          className="text-left font-weight-bold"
                          style={{ width: "200px" }}
                        >
                          Consignatária
                        </td>
                        <td
                          className="text-left font-weight-bold"
                          style={{ width: "150px" }}
                        >
                          CNPJ
                        </td>
                        <td
                          className="text-left font-weight-bold"
                          style={{ width: "100px" }}
                        >
                          Validade
                        </td>
                        <td
                          className="text-right font-weight-bold"
                          style={{ width: "120px" }}
                        >
                          Vlr Parcela
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {contratos &&
                        contratos.map((contrato, i) => (
                          <tr
                            key={i}
                            className={`${clickedTr.nrContrato === contrato?.nrContrato
                              ? "bg-secondary text-light"
                              : ""
                              }`}
                            onClick={() => {
                              handleContrato(contrato);
                            }}
                            onDoubleClick={() => {
                              handleContrato(contrato);
                            }}
                          >
                            <td
                              id={i}
                              className="text-left"
                              style={{ width: "120px" }}
                            >
                              {/* <input
                                type="text"
                                style={{ width: "100%", height: "100%" }}
                                // className="form-control"
                                // autoFocus
                                value={contrato?.nrContrato}
                                onChange={() => handleNrContrato(this, i)}
                              /> */}
                              {contrato?.nrContrato}
                            </td>
                            <td
                              id={i}
                              className="text-left"
                              style={{ width: "200px" }}
                            >
                              {contrato?.nmConsignataria}
                            </td>
                            <td
                              id={i}
                              className="text-left"
                              style={{ width: "150px" }}
                            >
                              {contrato?.nrCnpj}
                            </td>
                            <td
                              id={i}
                              className="text-left"
                              style={{ width: "100px" }}
                            >
                              {contrato?.dtValidade}
                            </td>
                            <td
                              id={i}
                              className="text-right"
                              style={{ width: "120px" }}
                            >{`R$ ${parseFloat(contrato?.vlParcelaPort).toFixed(
                              2
                            )}`}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </fieldset>
            ))}

          <Buttons
            first="Voltar"
            second="Simular"
            // disabledSecond={naoEnviar}
            goBack={goBack}
            submit={submit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    detalhes: state.dados.detalhes,
    proposta: state.dados.proposta,
  };
}

export default connect(mapStateToProps)(Form);
