import React, { useState, useEffect } from "react";

import Form from "../../components/form/admin/propostasV2";
import EnviarEmail from "../../components/form/admin/enviarEmail";
import ModalContent from "../../components/layout/ModalContent";
import GerirDocumentos from "../../components/form/admin/gerirDocumentos";
import Confirm from "../../views/simulator/confirm";
import IncluirPendencia from "../../components/form/admin/Pendencias/IncluirPendencia";

import { getData, sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import { cidades } from "../../helpers/cidades";
import { getCookie, saveCookie } from "../../helpers/general";
import {
  sortByString,
  sortByNumber,
  compareDate,
  width,
  Aguarde,
} from "../../helpers/general";
import Averbacao from "../../components/form/admin/Averbacao";
import AlteracaoProposta from "../../components/form/admin/alteracaoProposta";
import Auxiliar from "../../components/form/admin/Auxiliar";
import LogProposta from "../../components/form/admin/LogProposta";
import AntiFraude from "../../components/form/admin/AntiFraude";
import AuditAssinatura from "../../components/form/admin/AuditAssinatura";
import Observacoes from "../../components/form/admin/observacoes";
import Status from "../../views/status";
import SaldoDevedor from "../../views/admin/saldoDevedor";
import ConsultarMargem from "./ConsultarMargem";
import AlterarSimulacao from "../../components/form/admin/CreditoPessoal/alterarSimulacao";
import EditarProposta from "./../../routes/propostaAgente";
import Portabilidade from "./../../routes/portabilidade";
import Refinanciamento from "./../../routes/refinPort";
import SimularPort from "../../components/form/simulator/simularPort";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setAlterarProposta,
  setBuscarPropostas,
  setProposta,
  setNotificacoes,
  setFiltrosPropostas
} from "../../store/actions/dados";
import { confirmSwal, swalError } from "../../components/swal";
import PreencherChecklist from "../../components/form/admin/Conformidade/PreencherChecklist";
import ShowConformidade from "../../components/form/admin/Conformidade/ShowConformidade";
import GerarRefinAut from "../../components/form/admin/Refinanciamento/gerarRefinAut";
import ReapresentarTED from "../../components/form/admin/Reapresentacoes/ReapresentarTed";
import AuditBancaria from "../../components/form/admin/AuditBancaria";

function Propostas({ ...props }) {
  let estados = cidades();
  const [detailedPropostas, setDetailedPropostas] = useState([]);
  const [detailedShow, setDetailedShow] = useState([]);
  const [total, setTotal] = useState(false);
  const [clickedTr, setClickedTr] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState(false);
  const [filtro, setFiltro] = useState(false);
  const [ascDesc, setAscDesc] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [dateVisible, setDateVisible] = useState("todos");
  const [modal, setModal] = useState(false);
  const [averbacao, setAverbacao] = useState(false);
  const [pagamento, setPagamento] = useState(false);
  const [cancelamento, setCancelamento] = useState(false);
  const [inutilizacao, setInutilizacao] = useState(false);
  const [pendencia, setPendencia] = useState(false);
  const [bloqueio, setBloqueio] = useState(false);
  const [log, setLog] = useState(false);
  const [auditBanc, setAuditBanc] = useState(false);
  const [obs, setObs] = useState(false);
  const [status, setStatus] = useState(false);
  const [enviarEmail, setEnviarEmail] = useState(false);
  const [gerirDocs, setGerirDocs] = useState(false);
  const [action, setAction] = useState("");
  const [aguardando, _setAguardando] = useState(false);
  const [antiFraude, setAntiFraude] = useState(false);
  const [auditAssinatura, setAuditAssinatura] = useState(false);
  const [totalIni, setTotalIni] = useState(0);
  const [proposta_filter, setProposta_Filter] = useState("");
  const [simulation, setSimulation] = useState(false);
  const [simulationPort, setSimulationPort] = useState(false);
  const [gerarPropostaRefinPort, setGerarPropostaRefinPort] = useState(false);
  const [multSelect, setMultSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [consultarMargem, setConsultarMargem] = useState(false);
  const [solicitacaoSaldo, setSolicitacaoSaldo] = useState(false);
  const [alteracaoProposta, setAlteracaoProposta] = useState(false);
  const [alterarSimulacao, setAlterarSimulacao] = useState(false);
  const [noLimit, setNoLimit] = useState(getCookie({ label: "noLimit" }) || 'Nao')
  const [hideCanceled, setHideCanceled] = useState(getCookie({ label: "hideCanceled" }) || 'Nao')
  const [campoPesquisa, setCampoPesquisa] = useState('cpf')
  const [agentesGbl, setAgentesGbl] = useState(false)
  const [agentes, setAgentes] = useState(false)
  const [agentesMaster, setAgentesMaster] = useState(false)
  const [agenteId, setAgenteId] = useState(null)
  const [checklist, setChecklist] = useState(false)
  const [conformidade, setConformidade] = useState(false)
  const [reapresentarTED, setReapresentarTED] = useState(false)
  const [qtdLinesPage, setQtdLinesPage] = useState(10)
  const [limpar, setLimpar] = useState(false)
  const [totais, setTotais] = useState(false)
  const [convenios, setConvenios] = useState(false)
  const [user, setUser] = useState(props.user)
  const [procreditoID, setProcreditoID] = useState(props?.detalhes?.procreditoid)
  const [procreditoExec, setProcreditoExec] = useState(false)
  const [executarPesquisa, setExecutarPesquisa] = useState(false)
  const [analisando, setAnalisando] = useState(false)
  // const [filtraAgentes, setFiltraAgentes] = useState(false)

  useEffect(() => {
    if (executarPesquisa)
      handlePesquisar()
  }, [executarPesquisa]);

  //-- Inicializando dados de pesquisa
  // useEffect(() => {
  //   setDateVisible("status")
  //   // handleOption({ label: "startDate", value: new Date() - 30 }, true);
  //   // handleOption({ label: "finalDate", value: new Date() }, true);
  //   setStartDate(new Date() - 30);
  //   setFinalDate(new Date());
  //   // handleOption({ label: "created_at", value: [new Date() - 30, new Date()] }, true);
  //   console.log("propostas - useEffect(1): ", dateVisible)
  // }, []);

  function handleMarcarTodos(todos = false) {
    let _multSelect = [clickedTr]
    if (multSelect.length === 0) {
      if (!todos)
        detailedShow.map(
          (proposta, i) => {
            //-- Diogo 02.08.2023 - Comentado pois a opção de marcar todos não estava funcionando
            // if (i >= actualPage * qtdLinesPage && i < (actualPage + 1) * qtdLinesPage)
            _multSelect.push(proposta.uuid)
          })
      else
        for (let i = 0; i < detailedShow.length; i++) {
          // console.log("detailedShow[i].uuid: ", detailedShow[i])
          _multSelect.push(detailedShow[i].uuid)
        }
      setMultSelect(_multSelect)
    }
    else
      setMultSelect([])
  }

  function handleSelect(id) {
    setMultSelect(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(item => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  }

  function setAguardando(prm) {
    _setAguardando(prm);

    if (!prm) setBuscando(false);
  }

  function dblClick() {
    if (props.setAlterarProposta)
      props.setAlterarProposta(true, props.detalhes);

    // console.log("Detalhes: ", props.detalhes)
  }

  // useEffect(() => {
  //   setQtdLinesPage(getCookie({ label: "qtdLinesPage" }) || 10)
  // }, [getCookie({ label: "qtdLinesPage" })]);

  useEffect(() => {
    function callback(isConfirm) {
      setBuscando(false);
      let _action = action;
      setAction("");
      if (!isConfirm) return;
      // console.log("Executando ação: ", _action)
      //-- Modificando action manualmente
      if (
        _action.toLowerCase() == "enviarparaassinatura" &&
        props.detalhes.posicao.toLowerCase() == "assinando ccb"
      )
        _action = "reenviarParaAssinatura";

      setAguardando(true);
      const uuid = props.proposta;
      let content = { uuid, detalhes: props.detalhes };

      //-- Mudando content de uuid para proposta_uuid para não conflitar com uuid do documento, no caso da
      if (_action.toLowerCase() === "informarassinatura")
        content = { proposta_uuid: uuid };


      if (_action.toLowerCase() === "checarantifraude")
        content = { ...content, proposta_uuid: uuid, cpf: props?.detalhes?.cpf, forca: false }

      //-- Enviando para assinatura com token whatsapp
      if (_action.toLowerCase() === "enviarparaassinaturawhats")
        content = { ...content, whats: true };

      content["multSelect"] = multSelect;
      // console.log("Executando ação(1)")
      sendData({ uri: uriList(_action), content, method: "POST" })
        .then((res) => {
          // console.log(res);
          if (res && res.status && res.status != 200) {
            if (
              ["antifraude", "emailcliente", "reenviarparaassinatura"].indexOf(
                _action.toLowerCase()
              ) < 0
            )
              swalError({
                title: "Erro ao executar ação!",
                text: JSON.stringify(res.message || res),
              });
          }

          if (_action === "antifraude") {
            if (!res.status || res.status !== 200) {
              // console.log()
              setAntiFraude(res);
            } else setAguardando(false);
          } else refresh();
        })
        .catch((error) => {
          setAguardando(false);
          console.log("Erro - Action: ", _action);
          // console.log("Erro: ", error)
          if (_action !== "antifraude")
            swalError({ title: "Erro ao executar ação!", text: error });
        });
      setAction("");
    }

    if (action) {
      confirmSwal({ callback, title: "Confirma a execução do processo?" });
    }

  }, [action, refresh]);

  async function _getAgentesMst() {
    await sendData({
      uri: uriList("listarAgentesMst"),
      content: {},
      method: "POST",
    })
      .then((res) => {
        setAgentesGbl(res)
        console.log("_getAgentesMst.Agentes: ", res)
        let _agentes = []
        let _master = []
        res.map((agente) => {
          if (_agentes.indexOf(agente.nome) < 0)
            _agentes.push(agente.nome)

          if (_master.indexOf(agente.nome) < 0 && agente.supervisor_id == props.user.id)
            _master.push(agente.nome)
        })
        _agentes.sort()
        _master.sort()

        setAgentes(_agentes);
        setAgentesMaster(_master);

        // console.log("propostas._getAgentesMst: ", _agentes, _master)
        let _agenteid = props.detalhes.agente_id || (res && res[0] && res[0].id);
        setAgenteId(_agenteid);

        console.log("alteracaoProposta._getAgentesMst(agente): ", _agenteid)
      })
      .catch((e) => {
        console.log(e);
        alert("Erro ao obter Agentes.\n" + e.message);
      });
  }

  async function _getAgentes(convenio_id) {
    console.log("propostas._getAgentes: ", { user, props: props?.user })
    if (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) < 0) {
      _getAgentesMst()
      return false
    }

    console.log("propostas._getAgentes: ", convenio_id)

    await sendData({
      uri: uriList("listarAgentes"),
      content: {},
      method: "POST",
    })
      .then((res) => {
        let _master = []
        let _agentes = []
        setAgentesGbl(res)
        console.log("propostas._getAgentes(Agentes): ", res)
        const _res = res?.map((item) => {
          if (item.usermst_id && _master.indexOf(item.usermst_nome) < 0 &&
            (props.user.tipo != "OPERADOR PARCEIRO" || props.user.id == item.supervisor_id))
            _master.push(item.usermst_nome)

          if (item.nome && _agentes.indexOf(item.nome) < 0)
            _agentes.push(item.nome)
        });

        _master.sort()
        _agentes.sort()

        setAgentes(_agentes);
        setAgentesMaster(_master);

        // console.log("propostas._getAgentes: ", _agentes, _master)
        let _agenteid = props.detalhes.agente_id || (res && res[0] && res[0].id);
        setAgenteId(_agenteid);
        console.log("alteracaoProposta._getAgentes(agente): ", _agenteid)
      })
      .catch((e) => {
        console.log(e);
        alert("Erro ao obter Agentes.\n" + e.message);
      });
  }

  const handleProcredito = () => {
    function callback(e) {
      if (!e) return false;
      console.log("propostas - handleProcredito(1)")
      return new Promise((resolve, reject) => {
        setProcreditoExec(true)
        let uuid = props.proposta || props.uuid || props.uuid
        console.log("propostas - handleProcredito(1): ", uuid)
        sendData({ uri: uriList('processarProcredito'), content: { uuid }, method: 'POST' })
          .then(res => {
            console.log("propostas - handleProcredito(2): ", res?.id)
            resolve(true)
            setProcreditoID(true)
            console.log("propostas - handleProcredito(3)")
            setProcreditoExec(false)
          })
          .catch(e => {
            setProcreditoID(true)
            setProcreditoExec(false)
            reject(false)
          });
      })
    }

    confirmSwal({ callback, title: "Confirma a execução do processo?" });
  }

  const acompanharProposta = () => {
    return new Promise((resolve, reject) => {
      let uuid = props.proposta || props.uuid || props.uuid
      console.log("propostas - acompanharProposta(1): ", uuid)
      sendData({ uri: uriList('acompanharProposta'), content: { uuid }, method: 'POST' })
        .then(res => {
          console.log("propostas - acompanharProposta(2): ", res?.id)
          props.setProposta(res);
          resolve(true)
          console.log("propostas - acompanharProposta(3)")
        })
        .catch(e => {
          reject(false)
        });
    })
  }

  useEffect(() => {
    executarAcao()
    setProcreditoID(false)
  }, [props.action]);

  useEffect(() => {
    async function callBack() {
      getData({ uri: uriList("conveniosFilter") })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error();
          console.log("Convenios: ", res);
          let _convenios = []
          res.map((item) => _convenios.push(item.value))
          console.log("Convenios(1): ", _convenios);
          setConvenios(_convenios);
        })
        .catch((e) => {
          return false;
        });
    }

    callBack();
  }, []);

  async function executarAcao() {
    function callBack() {
      setBuscando(false);
      if (props.action === "pendenciar") setPendencia(true);
      else if (props.action === "bloquear") setBloqueio(true);
      else if (props.action === "averbar") setAverbacao(true);
      else if (props.action === "informarPagamento") setPagamento(true);
      else if (props.action === "cancelar") setCancelamento(true);
      else if (props.action === "inutilizar") setInutilizacao(true);
      else if (props.action === "log") setLog(true);
      else if (props.action === "auditBanc") setAuditBanc(true);
      else if (props.action === "auditAssinatura") setAuditAssinatura(true);
      else if (props.action === "obs") setObs(true);
      else if (props.action === "consultarMargem") setConsultarMargem(true);
      else if (props.action === "alterarSimulacao")
        acompanharProposta().then(setAlterarSimulacao);
      else if (props.action === "status")
        acompanharProposta().then(setStatus);
      else if (props.action === "enviarEmail") setEnviarEmail(true);
      else if (props.action === "gerirDocs") setGerirDocs(true);
      else if (props.action === "simulation")
        acompanharProposta().then(setSimulation);
      else if (props.action === "simularPortabilidade") setSimulationPort(true);
      // else if (props.action === "checklist") setChecklist(true);
      else if (props.action === "conformidade") setConformidade(true);
      else if (props.action === "reapresentarted") setReapresentarTED(true);
      else if (props.action === "consultarSolicitacaoSaldo")
        setSolicitacaoSaldo(true);
      else if (props.action === "assinarRefinPort" && props.setAlterarProposta)
        props.setAlterarProposta(true, props.detalhes);
      else if (props.action === "gerarPropostaRefinPort")
        setGerarPropostaRefinPort(true);
      else if (props.action === "alteracaoProposta") {
        console.log("propostas.executarAcao: alteracaoProposta")
        acompanharProposta().then(setAlteracaoProposta);
      }
      else setAction(props.action);
    }

    if (props.checklist && (!props?.detalhes?.multSelect || props?.detalhes?.multSelect?.length <= 1))
      setModal(<PreencherChecklist acao={props.action} handleCloseEv={() => setModal(false)} callBack={callBack} />)
    else
      callBack()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function refresh(close, forca) {
    const funcAtu = () => {
      setActualPage(0);
      props.setNotificacoes("limpar");
    };

    // console.log("propostas - refresh");
    setBuscando(false);
    setAguardando(false);
    setAverbacao(false);
    setPagamento(false);
    setCancelamento(false);
    setInutilizacao(false);
    setPendencia(false);
    setBloqueio(false);
    setEnviarEmail(false);
    setAntiFraude(false);
    setGerirDocs(false);
    setLog(false);
    setAuditBanc(false);
    setAuditAssinatura(false);
    setObs(false);
    setStatus(false);
    setConsultarMargem(false);
    setAlterarSimulacao(false);
    setAlteracaoProposta(false);
    setChecklist(false);
    setConformidade(false);
    setProcreditoID(false);
    setReapresentarTED(false);

    //-- Atualizando dados
    console.log("propostas - refresh(1): ", { close, forca, mult: props?.detalhes?.multSelect?.length })
    if (!close && (!props?.detalhes?.multSelect || props?.detalhes?.multSelect?.length <= 1)) {
      if (!forca) getProposta(funcAtu);
      else {
        getPropostas(true, 'refresh');
        funcAtu();
      }
    }
  }

  const sortArrObj = (
    key,
    type,
    array = null,
    grava = true,
    ordem = ascDesc
  ) => {
    if (!detailedShow) return;

    let arr = [...detailedShow];
    let asc = !ascDesc;
    if (array) {
      asc = ordem;
      arr = array;
    }
    arr = arr.sort((a, b) => {
      if (type === "string") {
        if (asc) {
          setAscDesc(asc);
          return sortByString(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByString(b, a, key, type);
        }
      } else if (type === "numeric") {
        if (asc) {
          setAscDesc(asc);
          return sortByNumber(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByNumber(b, a, key, type);
        }
      } else {
        if (asc) {
          setAscDesc(asc);
          return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
        } else {
          setAscDesc(asc);
          return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
        }
      }
    });

    if (grava)
      localStorage.setItem(
        "propostas_ordem",
        JSON.stringify({ key, type, asc: !ascDesc })
      );

    if (!array) setDetailedShow(arr);

    return array;
  };

  const filtrarPropostas = (filtros, propostas) => {
    // console.log("propostas - filtrarPropostas: ", { filtros, propostas, hideCanceled });
    let newDetailed = propostas;
    let _total = 0;
    newDetailed = propostas.filter((proposta) => {
      let result = true;
      // if (hideCanceled == 'Sim' && proposta.posicao == "Cancelada") {
      //   // console.log("propostas - filtrarPropostas(cancelada): ", { hideCanceled });
      //   result = false
      // }
      // else
      for (let _filtro in filtros) {
        if (filtros[_filtro] && filtros[_filtro] != "todos") {
          if (_filtro == "averbacao_solicitada") {
            result =
              (filtros[_filtro] == "Sim" && proposta["averbacao_solicitada"]) ||
              (filtros[_filtro] == "Nao" && !proposta["averbacao_solicitada"]);
          } else if (
            _filtro === "status_data" ||
            _filtro === "created_at" ||
            _filtro === "previsao_saldo"
          ) {
            result =
              result &&
              compareDate(new Date(proposta[_filtro]), startDate) &&
              compareDate(finalDate, new Date(proposta[_filtro]));
          } else if (_filtro === "proposta_filter") {
            // console.log("propostas - filtrarPropostas: (filtro)", filtros[_filtro])
            // console.log("propostas - filtrarPropostas: (nome)", proposta["nome"])
            result =
              result &&
              ((proposta["nome"] &&
                proposta["nome"]
                  .toLowerCase()
                  .indexOf(filtros[_filtro].toLowerCase()) >= 0) ||
                (proposta["uuid"] &&
                  proposta["uuid"].indexOf(filtros[_filtro]) >= 0) ||
                (proposta["proposta_uuid"] &&
                  proposta["proposta_uuid"].indexOf(filtros[_filtro]) >= 0) ||
                (proposta["agente"] &&
                  proposta["agente"]
                    .toUpperCase()
                    .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                (proposta["agentemaster"] &&
                  proposta["agentemaster"]
                    .toUpperCase()
                    .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                (proposta["operador"] &&
                  proposta["operador"]
                    .toUpperCase()
                    .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                (proposta["conformidadeapv"] &&
                  proposta["conformidadeapv"]
                    .toUpperCase()
                    .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                (proposta["cpf"] &&
                  filtros[_filtro].replace(/\D/g, "") != "" &&
                  proposta["cpf"]
                    .replace(/\D/g, "")
                    .indexOf(filtros[_filtro].replace(/\D/g, "")) >= 0) ||
                (proposta["cedula_numero"] &&
                  filtros[_filtro].replace(/\D/g, "") != "" &&
                  proposta["cedula_numero"]
                    .replace(/\D/g, "")
                    .indexOf(filtros[_filtro].replace(/\D/g, "")) >= 0));
            // console.log("propostas - filtrarPropostas: (result)", result)
          } else result = result && proposta[_filtro] === filtros[_filtro];
        }
      }

      if (result)
        _total += parseFloat(
          proposta.operacao.toLowerCase() == "refinanciamento"
            ? proposta.valor_liquido
            : proposta.valor_total
        );

      return result;
    });

    // console.log("propostas - filtrarPropostas(1): ", { newDetailed, _total });
    setTotal(_total);
    createFilter(newDetailed);

    return newDetailed;
  };

  const handleOption = (e) => {
    let label = e && e.currentTarget.dataset.label;
    let value = (e && e.currentTarget.value) || [startDate, finalDate];
    localStorage.setItem("propostas_ordem", null);
    console.log("propostas.handleOption(1): ", { label, value });

    let _filtros;
    if (
      label === "created_at" ||
      label === "status_data" ||
      label === "previsao_saldo" ||
      !label
    )
      _filtros = {
        ...filtro,
        status_data: null,
        created_at: null,
        previsao_saldo: null,
        [label]: value,
      };
    else if (label) _filtros = { ...filtro, [label]: value }
    else
      _filtros = { ...filtro, [label]: value }

    setFiltro(_filtros);
    props.setFiltrosPropostas(_filtros);

    // if (filtraAgentes && label === "agente" && agentesGbl) {
    //   let result = filtrarPropostas(_filtros, detailedPropostas);
    //   setDetailedShow(result);
    // }
  };

  const handleAnalisePropostas = async () => {
    console.log("propostas - handleAnalisePropostas: ")
    function callback(execute) {
      if (!execute) return;

      setAnalisando(true)
      sendData({
        uri: uriList("analisarPropostas"),
        content: {
          noLimit: true,
          campoPesquisa: campoPesquisa,
          proposta_filter: proposta_filter && proposta_filter?.trim().toUpperCase(),
          dateVisible,
          startDate,
          finalDate,
          filtro,
          agente_id: getAgenteId(),
          agentemst_id: getAgenteMstId(),
        },
        method: "POST",
      })
        .then((res) => {
          setAnalisando(false)
        })
        .catch((e) => {
          setAnalisando(false)
          swalError({ title: "Erro ao executar robô de analise.", text: e?.message || e?.data || "Erro desconhecido!" });

          return false;
        });

      console.log("propostas - handleAnalisePropostas(1): ")
    }

    confirmSwal({ callback, title: "Confirma análise das propostas?" });
  }

  const handlePesquisar = () => {
    console.log("propostas - handlePesquisar: ", proposta_filter);
    getPropostas(false, 'handlepesquisar', 0)
    setExecutarPesquisa(false)
  }

  const handleClearOptions = () => {
    setProposta_Filter();
    setFilterOptions(false);
    // console.log("FilterOptions: ", filterOptions);

    setDateVisible("todos");
    setProposta_Filter("");
    setTotal(totalIni);
    setFiltro(false);
    setClickedTr(false);
    localStorage.setItem("propostas_clickedTr", JSON.stringify({}));
    localStorage.setItem("propostas_ordem", JSON.stringify({}));
    setMultSelect([]);
    setCampoPesquisa('cpf')
    setDetailedPropostas([]);
    setDetailedShow([]);
    setTotais({});

    //-- Diogo 27.07.2023 - Retirando busca de registroar ao clicar em Limpar?    
    refresh(true);

    //-- Diogo 25.07.2023 - Executando rota em duplicidade
    // getPropostas(true, 'handleClearOptions');
  };

  const handleQtdLinesPage = (value) => {
    setQtdLinesPage(value);
    getPropostas(true, 'handleQtdLinesPage', 0, value);
  }

  const handlePage = (e) => {
    const _page = e?.currentTarget?.value
    console.log("propostas.handlePage: ", _page)
    setActualPage(_page);
    if (actualPage != _page)
      setMultSelect([]);

    getPropostas(true, '', _page)
  };

  const createFilter = (obj) => {
    // console.log("createFilter")
    let newObj = {
      created_at: [],
      status_data: [],
      previsao_saldo: [],
      cpf: [],
      tabela: [],
      convenio: [],
      agentemaster: [],
      agente: [],
      operador: [],
      conformidadeapv: [],
      posicao: [],
      operacao: [],
      averbacao_solicitada: ["Sim", "Nao"],
    };

    for (let o of obj) {
      for (let i in o) {
        if (i !== "averbacao_solicitada") {
          if (
            newObj[i] &&
            i !== "created_at" &&
            i !== "status_data" &&
            i !== "previsao_saldo"
          ) {
            newObj[i].push(o[i]);
          } else if (
            i === "created_at" ||
            i === "status_data" ||
            i === "previsao_saldo"
          ) {
            newObj[i].push(new Date(o[i]).toLocaleDateString());
          }
        }
      }
    }

    for (let i in newObj) {
      newObj[i] = [...new Set(newObj[i])];
    }

    // console.log("Propostas - Filtros: ", newObj);
    setFilterOptions(newObj);
  };

  function handleNoLimit(e) {
    let value = e.currentTarget.value
    saveCookie({ label: "noLimit", content: value })
    setNoLimit(value)
  }

  function handleHideCanceled(e) {
    let value = e.currentTarget.value
    saveCookie({ label: "hideCanceled", content: value })
    setHideCanceled(value)
  }

  // useEffect(() => {
  //   const result = filtrarPropostas(filtro, detailedPropostas);
  //   setDetailedShow(result);
  //   setActualPage(0);
  // }, [hideCanceled]) 

  const handleCampoPesquisa = (e) => {
    let value = e.currentTarget.value;
    setCampoPesquisa(value);
    console.log("propostas - handleCampoPesquisa: ", value);
  }

  function getAgenteId() {
    if (!agentesGbl)
      return null;

    const _agente = agentesGbl.find((agente) => agente.nome === filtro.agente);
    return _agente?.id;
  }

  function getAgenteMstId() {
    if (!agentesGbl)
      return null;

    const _agente = agentesGbl.find((agente) => agente.usermst_nome === filtro.agentemaster);
    return _agente?.usermst_id;
  }

  function _verificarCamposDiferentes(data, valorDesejado) {
    // Verificar se existem chaves no objeto data
    if (Object.keys(data).length === 0) {
      return false; // Se não houver chaves, retorna falso
    }

    for (const key in data) {
      // Verificar se algum valor é diferente do valorDesejado
      if (data[key] !== valorDesejado && data[key] !== "" && data[key] !== null) {
        return true; // Se encontrar um valor diferente, retorna verdadeiro
      }
    }
    return false; // Se nenhum valor diferente for encontrado, retorna falso
  }

  async function getPropostas(forca, origem, page, linesPage, refazendo = false) {
    console.log("propostas.getPropostas(Inicio): ", origem)
    setMultSelect([]);

    console.log("propostas.getPropostas(1): ", { proposta_filter, campoPesquisa, filtro, dateVisible })
    if (!_verificarCamposDiferentes(proposta_filter, 'todos') &&
      !_verificarCamposDiferentes(filtro, 'todos') &&
      (!dateVisible || dateVisible == "todos") && !forca) {
      swalError({ title: "Erro ao buscar propostas, tente novamente.", text: "Informe um valor para pesquisa!" });
      return false;
    }

    setDetailedPropostas([]);
    setDetailedShow([]);
    setTotal(0);
    setTotais({});
    setTotalIni(0);
    if (!page || page == undefined)
      setActualPage(0);

    let clicado = localStorage.getItem("propostas_clickedTr");
    if (clicado) clicado = JSON.parse(clicado);

    let ordem = localStorage.getItem("propostas_ordem");
    if (ordem) ordem = JSON.parse(ordem);

    console.log("propostas.getPropostas(campoPesquisa)", campoPesquisa?.currentTarget?.value)
    setBuscando(false);
    setBuscando(true);
    sendData({
      uri: uriList("resultado"),
      content: {
        noLimit: true,
        campoPesquisa: campoPesquisa,
        proposta_filter: proposta_filter && proposta_filter?.trim().toUpperCase(),
        dateVisible,
        startDate,
        finalDate,
        hideCanceled,
        filtro,
        actualPage: (page || 0) + 1,
        qtdLinesPage: linesPage || qtdLinesPage,
        agente_id: getAgenteId(),
        agentemst_id: getAgenteMstId(),
      },
      method: "POST",
    })
      .then((res) => {
        setBuscando(false);
        const { data, totais, usuarioMstNomes } = res;
        if (!res || res?.status > 200 || data == undefined || totais == undefined) throw new Error();

        console.log("propostas.getPropostas(ok): ", { data, totais, usuarioMstNomes })
        let _res;
        if (ordem && ordem.key)
          _res = sortArrObj(ordem.key, ordem.type, data, false, ordem.asc);
        else _res = data;

        setTotais(totais)

        //-- Filtrando propostas
        // console.log("propostas - getPropostas(y):", filtro);
        setDetailedPropostas(_res);
        setDetailedShow(_res);
      })
      .catch((e) => {
        setBuscando(false);
        console.log("erro ao buscar propostas: ", e);
        if (!refazendo)
          getPropostas(forca, origem, page, linesPage, true)
        else
          swalError({ title: "Erro ao buscar propostas, tente novamente.", text: e?.message || e?.data || "Erro desconhecido!" });

        return false;
      });
  }

  function getProposta(func) {
    let clicado = localStorage.getItem("propostas_clickedTr");
    if (clicado) clicado = JSON.parse(clicado);
    let content = { uuid: clicado?.clickedTr || clickedTr?.uuid };
    let prop = detailedPropostas.filter((proposta) => {
      return proposta.uuid === content.uuid;
    })[0];

    // console.log("getProposta: ", content);
    // console.log("getProposta(1): ", detailedPropostas);
    sendData({ uri: uriList("acompanhar"), content, method: "POST" })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        if (res && res.length > 0) {
          // console.log("getProposta(2): ", prop);
          // console.log("getProposta(2.1): ", res[0]);
          if (!prop || prop.posicao != res[0].posicao) {
            getPropostas(false, "getProposta");
            if (func) func();
          }
        }
      })
      .catch((e) => {
        console.log("erro ao buscar proposta: ", e);
        return false;
      });
  }

  useEffect(() => {
    if (!props.proposta) props.setAlterarProposta(false, props.detalhes);

    if (props.setBuscarPropostas)
      props.setBuscarPropostas(() => refresh(false, true));

    //-- Diogo voltar? 18.04.2023
    // refresh(false, true);

    _getAgentes();
  }, []);

  useEffect(() => {
    createFilter(detailedPropostas);
  }, [detailedPropostas]);

  function handleProposta(e) {
    // console.log("propostas - handleProposta: ", e.currentTarget);

    setProposta_Filter(e.currentTarget.value);
    localStorage.setItem("propostas_ordem", null);

    let _filtros = { ...filtro, proposta_filter: e.currentTarget.value };
    setFiltro(_filtros);

    //-- Diogo 02.08.2023 - Retirado filtro ao digitar
    // let result = filtrarPropostas(_filtros, detailedPropostas);
    // setDetailedShow(result);

    // setActualPage(0);
  }

  function handleClickTr(value, event) {
    setClickedTr(value);

    localStorage.setItem(
      "propostas_clickedTr",
      JSON.stringify({ clickedTr: value, actualPage })
    );

    if (event && event.ctrlKey) {
      if (multSelect.indexOf(value) < 0) setMultSelect([...multSelect, value]);
    } else if (!event?.context || multSelect.length <= 1) setMultSelect([value]);

  }

  // function Aguarde(props) {
  //   return (
  //     <Modal
  //       size={"md"}
  //       scrollable
  //       show={true}
  //       animation={false}
  //       backdrop="static"
  //       centered
  //     >
  //       <Modal.Body
  //         className="maxWidth750"
  //         style={{
  //           width: "100%",
  //           justifyContent: "center",
  //           textAlign: "center",
  //         }}
  //       >
  //         <Spinner animation="border" />
  //         {"  "}
  //         {buscando
  //           ? "Aguarde, buscando propostas..."
  //           : "Aguarde enquanto o processo é finalizado..."}
  //       </Modal.Body>
  //     </Modal>
  //   );
  // } 

  function StatusModal() {
    return (
      <>
        <ModalContent
          title="Status da Proposta"
          onCloseEv={() => {
            refresh(true);
          }}
          size={"lg"}
          concorda={false}
          procredito={
            !procreditoID &&
            ['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0 &&
            props?.detalhes?.posicao?.toLowerCase() == "pago" &&
            !props?.detalhes?.procreditoid
          }
          onAccept={handleProcredito}
        >
          <Status
            preencherCli={false}
            // title={width() === 'mobile' ? 'ACOMPANHAMENTO' : "ACOMPANHAMENTO DE PROPOSTA"}
            hideButton={true}
            hideTitle={true}
            modal={true}
            data={props.detalhes}

          />
        </ModalContent >
      </>
    );
  }

  // function HandleEditarProposta() {
  //     console.log("HandleEditarProposta:", props.detalhes)
  //     return ()
  //     // if (props.detalhes) 
  // }

  return (
    <>
      {!props.alterarProposta && (
        <Form
          filterOptions={filterOptions}
          proposta_filter={proposta_filter}
          handleProposta={handleProposta}
          handleOption={handleOption}
          handleClearOptions={handleClearOptions}
          setClickedTr={handleClickTr}
          setDateVisible={setDateVisible}
          setFinalDate={setFinalDate}
          setStartDate={setStartDate}
          setModal={setModal}
          sortArrObj={sortArrObj}
          detailedShow={detailedShow}
          actualPage={actualPage}
          handlePage={handlePage}
          finalDate={finalDate}
          startDate={startDate}
          dateVisible={dateVisible}
          clickedTr={clickedTr}
          multSelect={multSelect}
          setMultSelect={setMultSelect}
          detailedPropostas={detailedPropostas}
          DblClick={dblClick}
          total={total}
          context={props?.context}
          noLimit={noLimit}
          setNoLimit={handleNoLimit}
          buscando={buscando}
          hideCanceled={hideCanceled}
          setHideCanceled={handleHideCanceled}
          // buscarPropostas={() => getPropostas(true)}
          handlePesquisar={handlePesquisar}
          setCampoPesquisa={handleCampoPesquisa}
          campoPesquisa={campoPesquisa}
          handleMarcarTodos={handleMarcarTodos}
          qtdLinesPage={qtdLinesPage}
          setQtdLinesPage={handleQtdLinesPage}
          limpar={limpar}
          totais={totais}
          convenios={convenios}
          agentes={agentes}
          agentesMaster={agentesMaster}
          filtro={filtro}
          handleAnalisePropostas={handleAnalisePropostas}
        />
      )}

      {alteracaoProposta && (
        <AlteracaoProposta
          onCloseEv={() => {
            refresh(true);
          }}
          onHandle={() => refresh(false, true)}
          estados={estados}
          agentes={agentesGbl}
          agenteId={agenteId}
        />
      )}

      {props.alterarProposta &&
        (props.detalhes.operacao == "PORTABILIDADE" ? (
          props.detalhes.assinada || props.detalhes.assinando_refinport ? (
            <Refinanciamento alterar={true} />
          ) : (
            <Portabilidade alterar={true} />
          )
        ) : (
          <EditarProposta />
        ))}
      {antiFraude && (
        <AntiFraude
          onCloseEv={() => {
            refresh(true);
          }}
          res={antiFraude}
        />
      )}
      {auditAssinatura && (
        <AuditAssinatura
          onCloseEv={() => {
            refresh(true);
          }}
        />
      )}
      {averbacao && <Averbacao onCloseEv={refresh} tela="averbacao" />}
      {pagamento && <Averbacao onCloseEv={refresh} tela="pagamento" />}
      {pendencia && (
        <IncluirPendencia
          onCloseEv={refresh}
          uri="pendenciar"
          title="Pendenciar Proposta"
        />
      )}
      {bloqueio && (
        <Auxiliar
          onCloseEv={refresh}
          uri="bloquear"
          title="Bloquear Proposta"
        />
      )}
      {cancelamento && (
        <Auxiliar
          onCloseEv={refresh}
          multSelect={true}
          uri="cancelar"
          title="Cancelar Proposta"
          cancelar={true}
        />
      )}
      {inutilizacao && (
        <Auxiliar
          onCloseEv={refresh}
          uri="inutilizar"
          title="Inutilizar Proposta"
        />
      )}
      {log && (
        <LogProposta
          onCloseEv={() => {
            refresh(true);
          }}
        />
      )}
      {auditBanc && (
        <AuditBancaria
          onCloseEv={() => {
            refresh(true);
          }}
        />
      )}
      {obs && (
        <Observacoes
          onCloseEv={() => {
            refresh(true);
          }}
        />
      )}
      {procreditoExec && <Aguarde legenda="Aguarde, fazendo integração com o sistema Procrédito..." />}
      {analisando && <Aguarde legenda="Aguarde, analisando propostas..." />}
      {status && !procreditoExec && <StatusModal />}
      {simulation && (
        <Confirm
          onCloseEv={() => {
            refresh(true);
            setSimulation(false);
          }}
          onAccept={() => {
            refresh(false);
            setSimulation(false);
          }}
        />
      )}
      {simulationPort && (
        <SimularPort
          onCloseEv={() => {
            refresh(true);
            setSimulationPort(false);
          }}
          onAccept={() => {
            refresh(false);
            setSimulationPort(false);
          }}
        />
      )}
      {gerarPropostaRefinPort && (
        <GerarRefinAut
          uuid={props.proposta}
          onCloseEv={(data) => {
            refresh(true);
            setGerarPropostaRefinPort(false);

            if (data?.uuid) {
              setProposta_Filter(data.uuid)
              setCampoPesquisa('UUID Proposta')

              //-- Executa pesquisa automaticamente
              setExecutarPesquisa(true)
            }
          }}
        />
      )}
      {solicitacaoSaldo && (
        <SaldoDevedor
          onCloseEv={() => {
            refresh(true);
            setSolicitacaoSaldo(false);
          }}
          onAccept={() => {
            refresh(false);
            setSolicitacaoSaldo(false);
          }}
        />
      )}
      {enviarEmail && (
        <EnviarEmail
          onCloseEv={() => {
            refresh(true);
          }}
          onSend={() => {
            setAguardando(true);
          }}
          onEndSend={() => {
            setAguardando(false);
          }}
        />
      )}
      {gerirDocs && (
        <GerirDocumentos
          onCloseEv={() => {
            refresh(true);
          }}
        />
      )}
      {consultarMargem && <ConsultarMargem onCloseEv={() => refresh(true)} />}
      {/* {checklist && props?.detalhes?.assinada &&
        <PreencherChecklist
          acao={props?.detalhes?.operacao == "PORTABILIDADE" ? "pagarPortabilidade" : "enviarParaBanco"}
          handleCloseEv={() => {
            setModal(false)
            setChecklist(false)
          }} />
      } */}
      {conformidade &&
        <ShowConformidade
          handleCloseEv={() => {
            setModal(false)
            setConformidade(false)
          }} />
      }
      {reapresentarTED &&
        <ReapresentarTED onCloseEv={() => refresh(false, false)} />
      }
      {alterarSimulacao && (
        <AlterarSimulacao
          alterarSimulacao={true}
          onCloseEv={() => refresh(false, true)}
        />
      )}
      {(aguardando || buscando) && (
        <Aguarde
          legenda={
            buscando
              ? "Aguarde, buscando propostas..."
              : "Aguarde enquanto o processo é finalizado..."
          }
        />
      )}

      {modal}
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
    alterarProposta: state.dados.alterarProposta,
    buscarPropostas: state.dados.bucarPropostas,
  };
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setAlterarProposta(alterar, proposta) {
      dispatch(setAlterarProposta(alterar, proposta));
    },
    setBuscarPropostas(buscar) {
      dispatch(setBuscarPropostas(buscar));
    },
    setNotificacoes(notificacao) {
      dispatch(setNotificacoes(notificacao));
    },
    setFiltrosPropostas(filtros) {
      dispatch(setFiltrosPropostas(filtros));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Propostas);

//-- Teste